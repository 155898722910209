import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import pdfData from './pdfData';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
`;
const Check = styled.h2`
  font-size: 2em;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;

const PdfTitle = styled.h1`
  margin: 0;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;

const PdfDescription = styled.p`
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.text};
`;
const extraLinks = styled.p`
`;

const PdfIframe = styled.iframe`
  width: 100%;
  height: 800px;
  border: none;
`;

const PdfViewer = () => {
  const { id } = useParams();
  const pdf = pdfData.find((item) => item.id === parseInt(id, 10));

  if (!pdf) {
    return <Container>PDF not found</Container>;
  }

  const pdfSchema = {
    "@context": "http://schema.org",
    "@type": "Book",
    "name": pdf.topicName,
    "description": pdf.description,
    "url": window.location.href,
    "embedUrl": pdf.pdfUrl,
    "inLanguage": "en",
    "publisher": {
      "@type": "Organization",
      "name": "YouConect",
    }
  };

  return (
    <Container>
      <Helmet>
        <title>{pdf.topicName} - YouConect</title>
        <meta name="description" content={pdf.description} />
        <meta name="keywords" content="youconect, mdcat, youconect mdcat, MDcat preparation, free MDcat tests, MDcat study material, MDcat video lectures, MDcat notes, MDcat practice exams" />
        <script type="application/ld+json">{JSON.stringify(pdfSchema)}</script>
      </Helmet>
      <PdfTitle>{pdf.topicName}</PdfTitle>
      <PdfDescription>{pdf.description}</PdfDescription>
      <extraLinks>{pdf.extraLinks}</extraLinks>
      <PdfIframe src={pdf.pdfUrl} />
      <Check>Also Check</Check>
        <ul>
          <li><Link to="/questions/2" style={{color: "red", fontSize: "1.5em", fontWeight: "bold"}}>Chemistry Most Challenging Questions</Link></li>
          <li><Link to="/questions/3" style={{color: "red", fontSize: "1.5em", fontWeight: "bold"}}>Biology Most Challenging Questions</Link></li>
          <li><Link to="/questions/1" style={{color: "red", fontSize: "1.5em", fontWeight: "bold"}}>Physics Most Challenging Questions</Link></li>
          <li><Link to="/guide" style={{color: "red", fontSize: "1.5em", fontWeight: "bold"}}>How to Score 180+ Marks in MDCAT 2024</Link></li>
        </ul>
    </Container>
  );
};

export default PdfViewer;
