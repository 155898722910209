import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial, sans-serif';
  color: #333;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 2em;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 20px;
  text-align: center;
`;

const Intro = styled.p`
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  color: #2980b9;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Content = styled.p`
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const ContentList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;

  & > strong {
    color: #e74c3c;
  }
`;

const EngGuide = () => {
  return (
    <Container>
      <Title>Improving English Skills for MDCAT 2024: Tips and Tricks</Title>
      <Intro>
        Preparing for the MDCAT (Medical and Dental College Admission Test) 2024 can be a daunting task, especially when it comes to mastering the English section. This part of the exam tests your comprehension, vocabulary, and grammatical skills, all of which are crucial for a strong performance. Here are some tips and tricks to help you improve your English skills for MDCAT 2024.
      </Intro>
      <SectionTitle>1. Understand the Exam Structure</SectionTitle>
      <Content>
        Before diving into preparation, familiarize yourself with the structure of the English section in MDCAT 2024. The exam typically includes:
      </Content>
      <ContentList>
        <ListItem><strong>Reading Comprehension:</strong> Passages followed by questions testing your understanding and interpretation.</ListItem>
        <ListItem><strong>Vocabulary:</strong> Questions that test your knowledge of synonyms, antonyms, and contextual meanings.</ListItem>
        <ListItem><strong>Grammar:</strong> Questions focusing on sentence correction, error detection, and correct usage.</ListItem>
      </ContentList>
      <SectionTitle>2. Build a Strong Vocabulary</SectionTitle>
      <Content>
        A rich vocabulary is essential for tackling the vocabulary and comprehension sections effectively. Here are some strategies:
      </Content>
      <ContentList>
        <ListItem><strong>Read Regularly:</strong> Make reading a daily habit. Read newspapers, magazines, books, and academic journals. Focus on understanding the context and the meaning of new words.</ListItem>
        <ListItem><strong>Use Flashcards:</strong> Create flashcards for new words and review them regularly. Apps like Anki or Quizlet can be very useful.</ListItem>
        <ListItem><strong>Learn Word Roots:</strong> Understanding the roots, prefixes, and suffixes of words can help you decipher the meanings of unfamiliar words.</ListItem>
      </ContentList>
      <SectionTitle>3. Enhance Reading Comprehension Skills</SectionTitle>
      <Content>
        Reading comprehension requires not only understanding the text but also interpreting and analyzing it. Improve these skills by:
      </Content>
      <ContentList>
        <ListItem><strong>Active Reading:</strong> Engage with the text by highlighting key points, summarizing paragraphs, and asking questions.</ListItem>
        <ListItem><strong>Practice Summarizing:</strong> After reading a passage, summarize it in your own words. This helps reinforce your understanding.</ListItem>
        <ListItem><strong>Read a Variety of Texts:</strong> Diversify your reading material. Include fiction, non-fiction, scientific articles, and opinion pieces.</ListItem>
      </ContentList>
      <SectionTitle>4. Master Grammar and Usage</SectionTitle>
      <Content>
        A solid grasp of grammar is crucial for the sentence correction and error detection sections. Strengthen your grammar by:
      </Content>
      <ContentList>
        <ListItem><strong>Studying Grammar Rules:</strong> Review grammar rules and practice applying them. Use resources like grammar books or online platforms.</ListItem>
        <ListItem><strong>Practice Writing:</strong> Write essays, articles, or journal entries. Focus on using correct grammar and varied sentence structures.</ListItem>
        <ListItem><strong>Take Grammar Quizzes:</strong> Regularly test your knowledge with online grammar quizzes and exercises.</ListItem>
      </ContentList>
      <SectionTitle>5. Practice, Practice, Practice</SectionTitle>
      <Content>
        Consistent practice is key to success in the English section. Incorporate these into your study routine:
      </Content>
      <ContentList>
        <ListItem><strong>Solve Past Papers:</strong> Practice with past MDCAT papers to familiarize yourself with the question types and difficulty level.</ListItem>
        <ListItem><strong>Take Mock Tests:</strong> Simulate exam conditions by taking timed mock tests. This helps build your stamina and time management skills.</ListItem>
        <ListItem><strong>Analyze Your Performance:</strong> After each practice session, review your answers. Understand your mistakes and learn from them.</ListItem>
      </ContentList>
      <SectionTitle>6. Seek Feedback and Guidance</SectionTitle>
      <Content>
        Sometimes, external feedback can provide valuable insights into your preparation. Consider:
      </Content>
      <ContentList>
        <ListItem><strong>Joining Study Groups:</strong> Collaborate with peers to discuss and solve practice questions. Group studies can offer different perspectives and solutions.</ListItem>
        <ListItem><strong>Consulting Teachers or Tutors:</strong> If possible, seek guidance from teachers or tutors who can provide personalized feedback and tips.</ListItem>
      </ContentList>
      <SectionTitle>7. Stay Consistent and Positive</SectionTitle>
      <Content>
        Improving your English skills is a gradual process that requires persistence and a positive mindset. Keep the following in mind:
      </Content>
      <ContentList>
        <ListItem><strong>Set Realistic Goals:</strong> Break down your preparation into manageable tasks. Set achievable goals and celebrate small victories.</ListItem>
        <ListItem><strong>Stay Motivated:</strong> Remind yourself of your long-term objectives and stay committed to your study plan.</ListItem>
        <ListItem><strong>Take Care of Yourself:</strong> Ensure you get enough rest, eat healthily, and take breaks to avoid burnout.</ListItem>
      </ContentList>
      <SectionTitle>Conclusion</SectionTitle>
      <Content>
        Improving your English skills for MDCAT 2024 is a journey that involves understanding the exam structure, building vocabulary, enhancing reading comprehension, mastering grammar, practicing consistently, seeking feedback, and maintaining a positive attitude. By following these tips and tricks, you'll be well-equipped to tackle the English section and boost your overall performance in the exam. Good luck!
      </Content>
    </Container>
  );
};

export default EngGuide;
