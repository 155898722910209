import React from "react";
import styled from "styled-components";
import YouConect from "../img/logo.png";
import Search from "./Search";

const Container = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.bgLighter};
  height: 56px;
  z-index: 1000;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0px 20px;
  position: relative;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Img = styled.img`
  height: 30px;
`;

const Logo = styled.div`
  color: ${({ theme }) => theme.text};
  font-size: 30px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Navbar = () => {
  return (
    <Container>
      <Wrapper>
        <Left>
          <a href="/" style={{ textDecoration: "none", color: "inherit" }}>
            <Logo>
              <Img src={YouConect} alt="YouConect Logo" />
              YouConect
            </Logo>
          </a>
        </Left>
      </Wrapper>
    </Container>
  );
};

export default Navbar;
