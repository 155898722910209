import React from 'react';

const StudyGuide = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: 'auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
      color: '#333',
      background: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    title: {
      fontSize: '2.5em',
      color: '#2c3e50',
      textAlign: 'center',
      marginBottom: '20px',
    },
    intro: {
      fontSize: '1.2em',
      marginBottom: '20px',
      textAlign: 'justify',
    },
    sectionTitle: {
      fontSize: '1.8em',
      color: '#2980b9',
      marginBottom: '10px',
    },
    list: {
      listStyleType: 'disc',
      paddingLeft: '20px',
      marginBottom: '20px',
    },
    listItem: {
      marginBottom: '10px',
    },
    conclusion: {
      background: '#ecf0f1',
      padding: '15px',
      borderRadius: '8px',
    },
    conclusionText: {
      fontSize: '1.2em',
      textAlign: 'justify',
    },
    link: {
      color: '#2980b9',
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    linkHover: {
      textDecoration: 'underline',
    },
    linksContainer: {
      marginTop: '40px',
      textAlign: 'center',
    },
    linksTitle: {
      fontSize: '1.8em',
      color: '#2c3e50',
      marginBottom: '20px',
    },
    linkButton: {
      display: 'inline-block',
      padding: '10px 20px',
      margin: '10px',
      color: '#fff',
      backgroundColor: '#2980b9',
      borderRadius: '5px',
      textDecoration: 'none',
      transition: 'background-color 0.3s',
    },
    linkButtonHover: {
      backgroundColor: '#1f5a78',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Comprehensive Biology Study Guide for MDCAT</h1>
      <p style={styles.intro}>
        Biology is a important subject in the MDCAT exam, and passing with good marks can significantly boost your overall score. This comprehensive study guide will help you focus on the key topics and provide you with effective strategies to ace the biology section of MDCAT 2024.
      </p>

      <section>
        <h2 style={styles.sectionTitle}>1. Understanding the Syllabus</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Biodiversity: Study the classification of living organisms, focusing on the cellular level and the variety of life forms.</li>
          <li style={styles.listItem}>Bioenergetics: Learn about photosynthesis, respiration, and the ATP cycle.</li>
          <li style={styles.listItem}>Biological Molecules: Understand the structure and function of carbohydrates, lipids, proteins, and nucleic acids.</li>
          <li style={styles.listItem}>Cell Structure and Function: Review cell theory, cell organelles, and their functions.</li>
          <li style={styles.listItem}>Coordination and Control: Cover nervous and hormonal coordination in animals.</li>
          <li style={styles.listItem}>Diversity among Animals: Study the characteristics and classifications of different animal phyla.</li>
          <li style={styles.listItem}>Enzymes: Focus on enzyme action, factors affecting enzyme activity, and enzyme kinetics.</li>
          <li style={styles.listItem}>Evolution: Learn the principles of evolution, natural selection, and speciation.</li>
          <li style={styles.listItem}>Life Processes in Animals and Plants: Cover nutrition, gaseous exchange, and transport mechanisms.</li>
          <li style={styles.listItem}>Prokaryotes: Study bacteria and viruses, their structure, and functions.</li>
          <li style={styles.listItem}>Reproduction: Understand the reproductive systems and processes in plants and animals.</li>
          <li style={styles.listItem}>Support and Movement: Learn about the skeletal and muscular systems.</li>
          <li style={styles.listItem}>Variation and Genetics: Cover Mendelian genetics, DNA replication, and genetic variation.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>2. Creating a Study Plan</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}><strong>Daily Study Sessions:</strong> Dedicate 2-3 hours daily to biology, breaking it down into revising 1 or 2 chepters a day and solving its questions.</li>
          <li style={styles.listItem}><strong>Weekly Goals:</strong> Set specific topics to cover each week. For example, week 1 could focus on cell structure and function, while week 2 could focus on biological molecules.</li>
          <li style={styles.listItem}><strong>Regular Revision:</strong> Allocate time each week for revising previously covered topics to make sure not to forget any thing.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>3. Study Resources</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}><strong>Textbooks:</strong> Use recommended textbooks such as Dow Grades or relevant local publications.</li>
          <li style={styles.listItem}><strong>Online Resources:</strong> Access free study materials, video lectures, and free tests on our website.</li>
          <li style={styles.listItem}><strong>Past Papers:</strong> Practice with MDCATpast papers available for free on our website which is made according to exam format and question types.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>4. Effective Study Techniques</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}><strong>Active Learning:</strong> Learning by summarizing topics in your own words, create mind maps. to learn topics</li>
          <li style={styles.listItem}><strong>Practice Questions:</strong> Solve a variety of practice questions to test your understanding and improve your problem-solving skills.</li>
          <li style={styles.listItem}><strong>Flashcards:</strong> Use flashcards for quick revision of key terms and concepts.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>5. Focus on High-Yield Topics</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}><strong>Cell Biology:</strong> Ensure a thorough understanding of cell structure, organelles, and functions.</li>
          <li style={styles.listItem}><strong>Genetics:</strong> Focus on Mendelian genetics, DNA structure, and gene expression.</li>
          <li style={styles.listItem}><strong>Enzymes:</strong> Study enzyme kinetics, mechanisms, and factors affecting enzyme activity.</li>
          <li style={styles.listItem}><strong>Bioenergetics:</strong> Cover detailed mechanisms of photosynthesis and respiration.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>6. Practice and Review</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}><strong>Weekly Tests:</strong> Take our free weekly tests to assess your progress and identify areas needing improvement.</li>
          <li style={styles.listItem}><strong>Mock Exams:</strong> Simulate the actual exam conditions with full-length mock exams available on our website.</li>
          <li style={styles.listItem}><strong>Feedback:</strong> Review your test results to understand your mistakes and learn from them.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>7. Stay Healthy and Motivated</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}><strong>Balanced Diet:</strong> Eat a nutritious diet to keep your energy levels high.</li>
          <li style={styles.listItem}><strong>Exercise:</strong> Engage in regular physical activity to reduce stress.</li>
          <li style={styles.listItem}><strong>Rest:</strong> Ensure you get adequate sleep and take regular breaks during study sessions.</li>
        </ul>
      </section>

      <section style={styles.conclusion}>
        <h2 style={styles.sectionTitle}>Conclusion</h2>
        <p style={styles.conclusionText}>
          Scoring high in the MDCAT Biology section requires a strategic approach, consistent effort, and the right resources. By following this comprehensive study guide, you can effectively prepare for the exam and achieve your goal. Visit <a href="https://www.youconect.com/" target="_blank" rel="noopener noreferrer" style={styles.link} onMouseOver={(e) => e.currentTarget.style.textDecoration = styles.linkHover.textDecoration} onMouseOut={(e) => e.currentTarget.style.textDecoration = 'none'}>YouConect</a> for more resources, free past papers, and weekly tests to aid your preparation. Stay focused, stay motivated, and success will be within your reach!
        </p>
      </section>
      <div style={styles.linksContainer}>
        <h2 style={styles.linksTitle}>Explore More Resources</h2>
        <a 
          href="/questions/3" 
          style={styles.linkButton} 
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.linkButtonHover.backgroundColor} 
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.linkButton.backgroundColor}
        >
          Biology Challenging Questions
        </a>
        <a 
          href="/tests" 
          style={styles.linkButton} 
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.linkButtonHover.backgroundColor} 
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.linkButton.backgroundColor}
        >
          Mdcat Tests
        </a>
        <a 
          href="/chemguide" 
          style={styles.linkButton} 
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.linkButtonHover.backgroundColor} 
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.linkButton.backgroundColor}
        >
          Chemistry For MDCAT
        </a>
        <a 
          href="/phyguide" 
          style={styles.linkButton} 
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.linkButtonHover.backgroundColor} 
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.linkButton.backgroundColor}
        >
          Physics For MDCAT
        </a>
      </div>
    </div>
  );
};

export default StudyGuide;
