import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaLeaf } from 'react-icons/fa';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const FlashcardsContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-x: auto;
  right: 0;

  @media (max-width: 500px) {
    right: 50px;
  }
  @media (max-width: 390px) {
    right: 80px;
  }
`;

const Flashcard = styled.article`
  perspective: 1000px;
  margin: 20px 0;
  cursor: pointer;
`;

const FlashcardInner = styled.div`
  position: relative;
  width: 300px;
  height: 200px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: ${({ theme }) => theme.cardBackground};
  color: ${({ theme }) => theme.text};
  ${(props) => props.flipped && 'transform: rotateY(180deg);'}
`;

const FlashcardFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
`;

const FlashcardFront = styled(FlashcardFace)`
  background: ${({ theme }) => theme.cardBackground};
`;

const FlashcardBack = styled(FlashcardFace)`
  background: ${({ theme }) => theme.primary};
  color: #fff;
  transform: rotateY(180deg);
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
`;

const Button = styled.button`
  background: gray;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;

  &:hover {
    background: ${({ theme }) => theme.primaryHover};
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const ProgressIndicator = styled.div`
  margin: 10px 0;
  font-size: 1.2em;
  color: ${({ theme }) => theme.text};
`;

const ToggleButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background: blue;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;

  &:hover {
    background: ${({ theme }) => theme.primaryHover};
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const Heading = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
  color: #ffffff;
  text-align: center;
  margin: 20px 0;
  padding: 15px 20px;
  background: linear-gradient(45deg, #1d976c, #93f9b9);
  background-size: 400% 400%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  .icon {
    margin-right: 10px;
    font-size: 1.2em;
  }
`;
const LinksContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 100%;
`;

const NavLink = styled(Link)`
  background: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 1.5em;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;

  &:hover {
    background: #45a049;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;
const Flashcards = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);

  const flashcards = [
    { question: "What is the powerhouse of the cell?", answer: "Mitochondria" },
    { question: "What is the process of cell division?", answer: "Mitosis" },
    { question: "A viron is?", answer: "virus." },
    { question: "Viral genetic material is?", answer: "DNA or RNA" },
    { question: "The smallest virus is?", answer: "Picomavirus?" },
    { question: "Influenza virus contains?", answer: "RNA?" },
    { question: "Enzymes are present in?", answer: "mycoplasma, virus, spirochaetes and slime molds?" },
    { question: "Which organelles contain its own DNA?", answer: "Nucleus, mitochondria and chloroplast?" },
    { question: "An increase in heart rate is termed as?", answer: "Tachycardia?" },
    { question: "A decrease in the rate of beating of heart is called?", answer: "Bradycardia?" },
    { question: "Muscle fatigue occurs due to accumulation of?", answer: "RNA?" },
    { question: "Serum differs from blood in?", answer: "Lacking clotting factors." },
    { question: "keratin is a type of protein found in?", answer: "Nails." },
    { question: "How many liters of saliva inhale by a human?", answer: "Half liter." },
    { question: "Smooth muscles, cardiac muscles and glands are regulated by?", answer: "Autonomous nervous system." },
    { question: "The number of alveoli in human lungs is estimated to be around?", answer: "500 millions." },
    { question: "New alleles can be introduced into a populations gene pool by?", answer: "Mutation and Natural selection." },
    { question: "Stress indicating hormones are?", answer: "Cortisole, Adrenaline and ACTH." },
    { question: "Which tube joins kidney and bladder?", answer: "Uretar." },
    { question: "What is percentage of water present in our brain?", answer: "73%." },
    { question: "Human blood is a viscous fluid duee to?", answer: "Protains in blood." },
    { question: "Like enzyme co enzyme can be used?", answer: "again and again." },
    { question: "Deficiency of Vitamin C will cause?", answer: "Scurvy." },

    // Add more flashcards here
  ];

  const handleNext = () => {
    if (currentIndex < flashcards.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setShowAnswer(false);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setShowAnswer(false);
    }
  };

  const toggleAnswer = () => {
    setShowAnswer((prev) => !prev);
  };

  return (
    <>
      <Helmet>
        <title>Biology Flashcards | MDCAT Preparation</title>
        <meta name="description" content="Biology flashcards for MDCAT preparation. Learn key concepts  with our flashcards and ace the Mdcat." />
        <meta name="keywords" content="MDCAT, Biology, Flashcards, MDCAT Preparation, Learning, Biology Questions and Answers" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Biology Flashcards | MDCAT Preparation",
            "description": "Biology flashcards for MDCAT preparation. Learn key concepts and improve your knowledge with our interactive flashcards.",
            "mainEntity": {
              "@type": "CreativeWork",
              "name": "Biology Flashcards",
              "about": {
                "@type": "Thing",
                "name": "MDCAT Biology"
              },
              "educationalLevel": "High School",
              "isAccessibleForFree": "True",
              "learningResourceType": "Flashcards",
              "audience": {
                "@type": "Audience",
                "audienceType": "Students"
              },
              "keywords": "MDCAT, Biology, Flashcards, MDCAT Preparation, Learning, Biology Questions and Answers"
            }
          }
          `}
        </script>
      </Helmet>
      <FlashcardsContainer>
        <Heading>
          <FaLeaf className="icon" />
          Biology Flashcards
        </Heading>
        <Flashcard>
          <FlashcardInner flipped={showAnswer}>
            <FlashcardFront>
              {flashcards[currentIndex].question}
            </FlashcardFront>
            <FlashcardBack>
              {flashcards[currentIndex].answer}
            </FlashcardBack>
          </FlashcardInner>
        </Flashcard>
        <ToggleButton onClick={toggleAnswer}>
          {showAnswer ? "Hide Answer" : "Show Answer"}
        </ToggleButton>
        <ProgressIndicator>
          {currentIndex + 1} / {flashcards.length}
        </ProgressIndicator>
        <NavigationButtons>
          <Button onClick={handlePrev} disabled={currentIndex === 0}>
            Previous
          </Button>
          <Button onClick={handleNext} disabled={currentIndex === flashcards.length - 1}>
            Next
          </Button>
        </NavigationButtons>
        <LinksContainer>
          <NavLink to="/phyflashcard">Physics Flashcard</NavLink>
          <NavLink to="/chemflashcard">Chemistry Flashcard</NavLink>
        </LinksContainer>
      </FlashcardsContainer>
    </>
  );
};

export default Flashcards;
