import React, { useEffect, useState } from "react";
import styled from "styled-components";
import YouConect from "../img/logo.png";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import SettingsBrightnessOutlinedIcon from "@mui/icons-material/SettingsBrightnessOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchSubscriptions } from "../redux/userSlice";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";

const Container = styled.nav`
  flex: 1;
  background-color: ${({ theme }) => theme.bgLighter};
  height: 100vh;
  color: ${({ theme }) => theme.text};
  font-size: 14px;
  position: sticky;
  top: 0;

  @media (max-width: 768px) {
    width: 100vw;
    height: 100vh;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    font-size: 12px;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    transition: transform 0.3s ease-in-out;
  }
`;

const Wrapper = styled.div`
  padding: 18px 26px;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  margin-bottom: 25px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Img = styled.img`
  height: 25px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  padding: 15px 0px;
  margin: 10px 0; /* Added margin to create space between items */

  &:hover {
    background-color: ${({ theme }) => theme.soft};
  }

  @media (max-width: 768px) {
    gap: 10px;
    justify-content: center;
    padding: 10px;
  }
`;


const Hr = styled.hr`
  margin: 15px 0px;
  border: 0.5px solid ${({ theme }) => theme.soft};

  @media (max-width: 768px) {
    display: none;
  }
`;

const Login = styled.div`
  margin-top: 10px;
`;

const Button = styled.button`
  padding: 5px 15px;
  background-color: transparent;
  border: 1px solid #3ea6ff;
  color: #3ea6ff;
  border-radius: 3px;
  font-weight: 500;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;

  @media (max-width: 768px) {
    padding: 5px 10px;
  }
`;

const SubscriptionsSection = styled.section`
  margin-top: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const SubscriptionChannel = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const ShowMoreButton = styled.button`
  padding: 5px;
  background-color: transparent;
  border: 1px solid #3ea6ff;
  color: #3ea6ff;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const HamburgerIcon = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: 45px;
    right: 10px;
    z-index: 1100;
    cursor: pointer;

    div {
      width: 25px;
      height: 3px;
      background-color: ${({ theme }) => theme.text};
      margin: 5px 0;
      transition: 0.4s;
    }
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

const FilterButton = styled.button`
  background-color: ${({ active }) => (active ? "#cc1a00" : "#f1f1f1")};
  color: ${({ active }) => (active ? "white" : "black")};
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin: 5px 0; // Adjusted margin for vertical spacing
  border-radius: 20px;
  &:hover {
    background-color: #cc1a00;
    color: white;
  }
`;

const Menu = ({ darkMode, setDarkMode }) => {
  const dispatch = useDispatch();
  const [videos, setVideos] = useState([]);
  const [filter, setFilter] = useState("all");
  const [page, setPage] = useState(1);
  const { currentUser } = useSelector((state) => state.user);
  const [menuOpen, setMenuOpen] = useState(false);

  const tags = ["Youconect", "Mdcat"];

  useEffect(() => {
    if (currentUser && currentUser.id) {
      dispatch(fetchSubscriptions(currentUser.id));
    }
  }, [dispatch, currentUser]);

  const handleMenuItemClick = () => {
    setMenuOpen(false);
  };

  const handleFilterChange = (tag) => {
    setFilter(tag.toLowerCase());
    setVideos([]);
    setPage(1);
  };

  return (
    <>
      <HamburgerIcon onClick={() => setMenuOpen(!menuOpen)}>
        <div />
        <div />
        <div />
      </HamburgerIcon>
      <Container open={menuOpen}>
        <Wrapper>
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }} onClick={handleMenuItemClick}>
            <Logo>
              <Img src={YouConect} alt="YouConect Logo" loading="lazy" />
              YouConect
            </Logo>
          </Link>
          <Link to="/feedback" style={{ textDecoration: "none", color: "inherit" }} aria-label="Send Feedback" onClick={handleMenuItemClick}>
            <Item>
              <FeedbackOutlinedIcon />
              Send Feedback
            </Item>
          </Link>
          <Hr />
          <FilterContainer>
            {tags.map((tag) => (
              <FilterButton
                key={tag}
                active={filter === tag.toLowerCase()}
                onClick={() => handleFilterChange(tag)}
              >
                {tag}
              </FilterButton>
            ))}
          </FilterContainer>
          <Hr />
          <Link to="/tests" style={{ textDecoration: "none", color: "inherit" }} aria-label="Settings" onClick={handleMenuItemClick}>
            <Item>
              <SettingsOutlinedIcon />
              Test
            </Item>
          </Link>
          <Hr />
          <Link to="/aboutus" style={{ textDecoration: "none", color: "inherit" }} aria-label="Settings" onClick={handleMenuItemClick}>
            <Item>
              <SettingsOutlinedIcon />
              AboutUs
            </Item>
          </Link>
          <Hr />
          <Link to="/contactus" style={{ textDecoration: "none", color: "inherit" }} aria-label="Report" onClick={handleMenuItemClick}>
            <Item>
              <FlagOutlinedIcon />
              ContactUs
            </Item>
          </Link>
          <Hr />
          <Link to="/help" style={{ textDecoration: "none", color: "inherit" }} aria-label="Help" onClick={handleMenuItemClick}>
            <Item>
              <HelpOutlineOutlinedIcon />
              Help
            </Item>
          </Link>
          <Hr />
          <Link to="/privacy" style={{ textDecoration: "none", color: "inherit" }} aria-label="Privacy Policy" onClick={handleMenuItemClick}>
            <Item>
              <PrivacyTipOutlinedIcon />
              Privacy Policy
            </Item>
          </Link>
          <Hr />
          <Item onClick={() => setDarkMode(!darkMode)}>
            <SettingsBrightnessOutlinedIcon />
            {darkMode ? "Light" : "Dark"} Mode
          </Item>
        </Wrapper>
      </Container>
    </>
  );
};

export default Menu;
