// src/components/QuestionDetail.jsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';

const Container = styled.div`
  padding: 20px;
  margin: auto;
  max-width: 800px;
`;
const Check = styled.h2`
  font-size: 2em;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;
const QuestionTitle = styled.h2`
  color: ${({ theme }) => theme.text};
  text-align: center;
  font-size: 1.8em; 
  margin-bottom: 20px;
`;

const QuestionContent = styled.div`
  margin-top: 20px;
  color: ${({ theme }) => theme.text};
  font-size: 1.2em;
`;

const QuestionParagraph = styled.p`
  margin-top: 20px;
  line-height: 1.6;
`;

const QuestionList = styled.ul`
  margin-top: 10px;
  margin-bottom: 20px;
`;

const CheckButton = styled.button`
  margin-top: 10px;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.buttonText};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const CorrectAnswer = styled.p`
  color: green;
  font-weight: bold;
  margin-top: 10px;
`;

const questions = {
  "1": {
    title: "Physics Most Challenging Questions",
    content: [
      {
        question: "A car accelerates uniformly from rest to a speed of 30 m/s in 10 seconds. What is the car's acceleration?",
        options: ["a) 3 m/s²", "b) 2.5 m/s²", "c) 3.5 m/s²", "d) 4 m/s²"],
        correctAnswer: "a) 3 m/s²",
      },
      {
        question: "A body of mass 10 kg is moving with a velocity of 5 m/s. What is its momentum?",
        options: ["a) 50 kg m/s", "b) 40 kg m/s", "c) 60 kg m/s", "d) 30 kg m/s"],
        correctAnswer: "a) 50 kg m/s",
      },
      {
        question: "A 50 kg object is lifted to a height of 10 meters. What is the gravitational potential energy gained by the object? (g = 9.8 m/s²)",
        options: ["a) 4900 J", "b) 5000 J", "c) 4800 J", "d) 4500 J"],
        correctAnswer: "a) 4900 J",
      },
      {
        question: "If 1000 J of work is done to move a 50 kg object, what distance is covered if the force applied is 20 N?",
        options: ["a) 50 m", "b) 40 m", "c) 30 m", "d) 20 m"],
        correctAnswer: "b) 50 m",
      },
      {
        question: "A wheel of radius 0.5 m rotates with an angular velocity of 2 rad/s. What is the linear velocity of a point on the rim of the wheel?",
        options: ["a) 1 m/s", "b) 2 m/s", "c) 3 m/s", "d) 4 m/s"],
        correctAnswer: "b) 1 m/s",
      },
      {
        question: "A mass of 5 kg is whirled in a horizontal circle of radius 2 m at a speed of 3 m/s. What is the centripetal force acting on the mass?",
        options: ["a) 15 N", "b) 20 N", "c) 22.5 N", "d) 25 N"],
        correctAnswer: "c) 22.5 N",
      },
      {
        question: "The frequency of a wave is 500 Hz and its wavelength is 0.4 m. What is the speed of the wave?",
        options: ["a) 200 m/s", "b) 150 m/s", "c) 250 m/s", "d) 300 m/s"],
        correctAnswer: "a) 200 m/s",
      },
      {
        question: "A sound wave travels at 340 m/s in air. What is the wavelength of a sound wave with a frequency of 1700 Hz?",
        options: ["a) 0.2 m", "b) 0.1 m", "c) 0.5 m", "d) 0.3 m"],
        correctAnswer: "a) 0.2 m",
      },
      {
        question: "The first law of thermodynamics is a statement of the conservation of:",
        options: ["a) Mass", "b) Energy", "c) Momentum", "d) Charge"],
        correctAnswer: "b) Energy",
      },
      {
        question: "If a system does 50 J of work and gains 30 J of heat, what is the change in its internal energy?",
        options: ["a) -20 J", "b) 20 J", "c) 80 J", "d) -80 J"],
        correctAnswer: "a) -20 J",
      },
      {
        question: "Two charges of +3 μC and -3 μC are placed 2 cm apart. What is the force between them? (k = 9 x 10^9 N m²/C²)",
        options: ["a) 202.5 N", "b) 405 N", "c) 607.5 N", "d) 810 N"],
        correctAnswer: "c) 202.5 N",
      },
      {
        question: "What is the electric potential energy of a 2 μC charge placed in an electric potential of 100 V?",
        options: ["a) 0.2 J", "b) 0.1 J", "c) 0.3 J", "d) 0.4 J"],
        correctAnswer: "b) 0.2 J",
      },
      {
        question: "A resistor of 5 ohms is connected in series with a 10 ohm resistor. What is the total resistance?",
        options: ["a) 3 ohms", "b) 5 ohms", "c) 10 ohms", "d) 15 ohms"],
        correctAnswer: "d) 15 ohms",
      },
      {
        question: "A current of 2 A flows through a resistor of 4 ohms. What is the power dissipated in the resistor?",
        options: ["a) 16 W", "b) 8 W", "c) 4 W", "d) 2 W"],
        correctAnswer: "a) 16 W",
      },
      {
        question: "A wire carrying a current of 3 A is placed in a magnetic field of 2 T. What is the force on a 1 m length of the wire?",
        options: ["a) 4 N", "b) 5 N", "c) 6 N", "d) 7 N"],
        correctAnswer: "c) 6 N",
      },
      {
        question: "What is the magnetic flux through a loop of area 0.2 m² in a magnetic field of 3 T?",
        options: ["a) 0.6 Wb", "b) 0.8 Wb", "c) 0.5 Wb", "d) 0.4 Wb"],
        correctAnswer: "a) 0.6 Wb",
      },
      {
        question: "A coil of 50 turns is placed in a magnetic field that changes at a rate of 0.1 T/s. What is the induced EMF?",
        options: ["a) 5 V", "b) 2.5 V", "c) 10 V", "d) 7.5 V"],
        correctAnswer: "a) 5 V",
      },
      {
        question: "The self-inductance of a coil is 2 H. If the current changes at a rate of 3 A/s, what is the induced EMF?",
        options: ["a) 4 V", "b) 5 V", "c) 6 V", "d) 3 V"],
        correctAnswer: "c) 6 V",
      },
      {
        question: "In a semiconductor diode, what happens during forward biasing?",
        options: ["a) Electrons move from p-type to n-type", "b) Holes move from n-type to p-type", "c) Electrons move from n-type to p-type", "d) Both a and b"],
        correctAnswer: "c) Electrons move from n-type to p-type",
      },
      {
        question: "What is the voltage gain of an amplifier with an input voltage of 2 V and an output voltage of 10 V?",
        options: ["a) 5", "b) 6", "c) 4", "d) 3"],
        correctAnswer: "a) 5",
      },
      {
        question: "According to the photoelectric effect, what is ejected when light strikes a metal surface?",
        options: ["a) Protons", "b) Neutrons", "c) Electrons", "d) Photons"],
        correctAnswer: "c) Electrons",
      },
      {
        question: "Which scientist proposed the quantum theory of radiation?",
        options: ["a) Newton", "b) Einstein", "c) Planck", "d) Bohr"],
        correctAnswer: "c) Planck",
      },
      {
        question: "What causes the emission of spectral lines in an atom?",
        options: ["a) Electron transitions between energy levels", "b) Protons moving in the nucleus", "c) Neutron decay", "d) Photon absorption"],
        correctAnswer: "a) Electron transitions between energy levels",
      },
      {
        question: "In the hydrogen atom, what is the wavelength of the first line in the Lyman series? (Rydberg constant RH = 1.097 × 107 m−1)",
        options: ["a) 121.6 nm", "b) 102.6 nm", "c) 93.8 nm", "d) 91.2 nm"],
        correctAnswer: "d) 121.6 nm",
      },
      {
        question: "What is the half-life of a radioactive substance if 75% of it decays in 10 hours?",
        options: ["a) 5 hours", "b) 6 hours", "c) 7 hours", "d) 8 hours"],
        correctAnswer: "b) 5 hours",
      },
    ],
  },
  "2": {
  title: "Chemistry Most Challenging Questions",
  content: [
    {
      question: "Which of the following elements is most likely to form a cation?",
      options: ["a) Fluorine", "b) Oxygen", "c) Sodium", "d) Neon"],
      correctAnswer: "c) Sodium",
    },
    {
      question: "Which of the following compounds is the least reactive?",
      options: ["a) Benzene", "b) Ethylene", "c) Acetylene", "d) Cyclohexane"],
      correctAnswer: "a) Benzene",
    },
    {
      question: "What is the IUPAC name of CH₃CH₂OH?",
      options: ["a) Methanol", "b) Ethanol", "c) Propanol", "d) Butanol"],
      correctAnswer: "b) Ethanol",
    },
    {
      question: "Which law states that 'equal volumes of gases at the same temperature and pressure contain the same number of molecules'?",
      options: ["a) Boyle's Law", "b) Charles's Law", "c) Avogadro's Law", "d) Dalton's Law"],
      correctAnswer: "c) Avogadro's Law",
    },
    {
      question: "The energy required to remove an electron from an atom is known as:",
      options: ["a) Electron affinity", "b) Ionization energy", "c) Electronegativity", "d) Atomic radius"],
      correctAnswer: "b) Ionization energy",
    },
    {
      question: "The ideal gas equation is represented by:",
      options: ["a) PV = nRT", "b) PV = nR/T", "c) PV = nRT^2", "d) P = nRT/V"],
      correctAnswer: "a) PV = nRT",
    },
    {
      question: "The property of liquids that causes them to resist an external force is called:",
      options: ["a) Viscosity", "b) Surface tension", "c) Capillarity", "d) Adhesion"],
      correctAnswer: "b) Surface tension",
    },
    {
      question: "The crystal structure of diamond is:",
      options: ["a) Body-centered cubic", "b) Face-centered cubic", "c) Hexagonal close-packed", "d) Tetrahedral"],
      correctAnswer: "d) Tetrahedral",
    },
    {
      question: "In the reaction N₂ + 3H₂ ⇌ 2NH₃, increasing the pressure will:",
      options: ["a) Shift equilibrium to the right", "b) Shift equilibrium to the left", "c) Have no effect", "d) Decompose NH₃"],
      correctAnswer: "a) Shift equilibrium to the right",
    },
    {
      question: "The rate of a chemical reaction depends on:",
      options: ["a) The nature of reactants", "b) Temperature", "c) Concentration of reactants", "d) All of the above"],
      correctAnswer: "d) All of the above",
    },
    {
      question: "An exothermic reaction is characterized by:",
      options: ["a) Absorption of heat", "b) Release of heat", "c) Decrease in temperature", "d) Increase in enthalpy"],
      correctAnswer: "b) Release of heat",
    },
    {
      question: "In an electrochemical cell, the electrode where oxidation occurs is called the:",
      options: ["a) Anode", "b) Cathode", "c) Salt bridge", "d) Electrode"],
      correctAnswer: "a) Anode",
    },
    {
      question: "The bond angle in a water molecule is approximately:",
      options: ["a) 90 degrees", "b) 104.5 degrees", "c) 120 degrees", "d) 180 degrees"],
      correctAnswer: "b) 104.5 degrees",
    },
    {
      question: "Which of the following elements is a member of the s-block?",
      options: ["a) Sodium", "b) Carbon", "c) Nitrogen", "d) Chlorine"],
      correctAnswer: "a) Sodium",
    },
    {
      question: "Which of the following is a characteristic property of transition metals?",
      options: ["a) Low melting points", "b) High ionization energies", "c) Formation of colored compounds", "d) Low density"],
      correctAnswer: "c) Formation of colored compounds",
    },
    {
      question: "The functional group -COOH is characteristic of:",
      options: ["a) Alcohols", "b) Carboxylic acids", "c) Ketones", "d) Ethers"],
      correctAnswer: "b) Carboxylic acids",
    },
    {
      question: "Alkanes are also known as:",
      options: ["a) Aromatic hydrocarbons", "b) Saturated hydrocarbons", "c) Unsaturated hydrocarbons", "d) Heterocyclic compounds"],
      correctAnswer: "b) Saturated hydrocarbons",
    },
    {
      question: "The IUPAC name for CH₃-CH₂-Cl is:",
      options: ["a) Methyl chloride", "b) Ethyl chloride", "c) Propyl chloride", "d) Chloromethane"],
      correctAnswer: "b) Ethyl chloride",
    },
    {
      question: "The compound with the formula C₆H₅OH is known as:",
      options: ["a) Ethanol", "b) Methanol", "c) Phenol", "d) Benzene"],
      correctAnswer: "c) Phenol",
    },
    {
      question: "The general formula for aldehydes is:",
      options: ["a) R-CHO", "b) R-COOH", "c) R-CO-R'", "d) R-OH"],
      correctAnswer: "a) R-CHO",
    },
    {
      question: "The common name for ethanoic acid is:",
      options: ["a) Formic acid", "b) Acetic acid", "c) Propionic acid", "d) Butyric acid"],
      correctAnswer: "b) Acetic acid",
    },
    {
      question: "Which of the following is a synthetic polymer?",
      options: ["a) Cellulose", "b) Starch", "c) Nylon", "d) DNA"],
      correctAnswer: "c) Nylon",
    },
    {
      question: "According to Graham's law, the rate of diffusion of a gas is inversely proportional to the:",
      options: ["a) Square of its molar mass", "b) Square root of its molar mass", "c) Cube of its molar mass", "d) Cube root of its molar mass"],
      correctAnswer: "b) Square root of its molar mass",
    },
    {
      question: "The temperature at which the vapor pressure of a liquid equals the external pressure is called:",
      options: ["a) Melting point", "b) Boiling point", "c) Critical point", "d) Triple point"],
      correctAnswer: "b) Boiling point",
    },
    {
      question: "The packing efficiency in a body-centered cubic lattice is:",
      options: ["a) 52%", "b) 68%", "c) 74%", "d) 90%"],
      correctAnswer: "b) 68%",
    },
    {
      question: "Le Chatelier’s principle is used to predict the effect of changes in:",
      options: ["a) Temperature", "b) Pressure", "c) Concentration", "d) All of the above"],
      correctAnswer: "d) All of the above",
    },
    {
      question: "The half-life of a first-order reaction is:",
      options: ["a) Directly proportional to the initial concentration", "b) Inversely proportional to the initial concentration", "c) Independent of the initial concentration", "d) Proportional to the square of the initial concentration"],
      correctAnswer: "c) Independent of the initial concentration",
    },
    {
      question: "The enthalpy change when one mole of a substance is formed from its elements in their standard states is called:",
      options: ["a) Enthalpy of formation", "b) Enthalpy of combustion", "c) Enthalpy of fusion", "d) Enthalpy of vaporization"],
      correctAnswer: "a) Enthalpy of formation",
    },
  ],
}, 
"3": {
  title: "Biology Most Challenging Questions",
  content: [
    {
      question: "In which form is energy primarily stored in cells?",
      options: ["a) ATP", "b) DNA", "c) Glucose", "d) Proteins"],
      correctAnswer: "a) ATP",
    },
    {
      question: "Which type of bond is formed between amino acids in a protein?",
      options: ["a) Hydrogen bond", "b) Ionic bond", "c) Peptide bond", "d) Covalent bond"],
      correctAnswer: "c) Peptide bond",
    },
    {
      question: "What is the primary function of ribosomes in a cell?",
      options: ["a) DNA replication", "b) Protein synthesis", "c) Lipid metabolism", "d) Energy production"],
      correctAnswer: "b) Protein synthesis",
    },
    {
      question: "Which organelle is responsible for cellular respiration?",
      options: ["a) Chloroplast", "b) Nucleus", "c) Mitochondria", "d) Golgi apparatus"],
      correctAnswer: "c) Mitochondria",
    },
    {
      question: "What is the role of chlorophyll in photosynthesis?",
      options: ["a) Absorbing water", "b) Absorbing light", "c) Fixing carbon dioxide", "d) Releasing oxygen"],
      correctAnswer: "b) Absorbing light",
    },
    {
      question: "Which hormone is produced by the adrenal medulla?",
      options: ["a) Insulin", "b) Thyroxine", "c) Cortisol", "d) Adrenaline"],
      correctAnswer: "d) Adrenaline",
    },
    {
      question: "What is the main function of the myelin sheath?",
      options: ["a) Protection of neurons", "b) Nutrient transport", "c) Speeding up nerve impulses", "d) Signal transmission at synapses"],
      correctAnswer: "c) Speeding up nerve impulses",
    },
    {
      question: "Which process involves the movement of water across a semi-permeable membrane?",
      options: ["a) Diffusion", "b) Osmosis", "c) Active transport", "d) Endocytosis"],
      correctAnswer: "b) Osmosis",
    },
    {
      question: "What is the structural unit of the nervous system?",
      options: ["a) Axon", "b) Dendrite", "c) Neuron", "d) Synapse"],
      correctAnswer: "c) Neuron",
    },
    {
      question: "What causes the heart to contract?",
      options: ["a) Sinoatrial node", "b) Atrioventricular node", "c) Purkinje fibers", "d) Bundle of His"],
      correctAnswer: "a) Sinoatrial node",
    },
    {
      question: "Which enzyme breaks down starch into maltose?",
      options: ["a) Lactase", "b) Amylase", "c) Protease", "d) Lipase"],
      correctAnswer: "b) Amylase",
    },
    {
      question: "In which part of the cell does glycolysis occur?",
      options: ["a) Mitochondria", "b) Cytoplasm", "c) Nucleus", "d) Endoplasmic reticulum"],
      correctAnswer: "b) Cytoplasm",
    },
    {
      question: "What is the genetic material in a prokaryotic cell?",
      options: ["a) RNA", "b) DNA", "c) Plasmid", "d) Chromatin"],
      correctAnswer: "b) DNA",
    },
    {
      question: "What is the primary structure of a protein?",
      options: ["a) Amino acid sequence", "b) Alpha-helix", "c) Beta-pleated sheet", "d) Tertiary structure"],
      correctAnswer: "a) Amino acid sequence",
    },
    {
      question: "Which part of the brain controls voluntary movements?",
      options: ["a) Cerebrum", "b) Cerebellum", "c) Medulla oblongata", "d) Thalamus"],
      correctAnswer: "a) Cerebrum",
    },
    {
      question: "Which process is responsible for the formation of gametes?",
      options: ["a) Mitosis", "b) Meiosis", "c) Binary fission", "d) Budding"],
      correctAnswer: "b) Meiosis",
    },
    {
      question: "What is the main function of hemoglobin in the blood?",
      options: ["a) Fighting infections", "b) Transporting oxygen", "c) Clotting blood", "d) Breaking down toxins"],
      correctAnswer: "b) Transporting oxygen",
    },
    {
      question: "Which vitamin is essential for blood clotting?",
      options: ["a) Vitamin A", "b) Vitamin C", "c) Vitamin D", "d) Vitamin K"],
      correctAnswer: "d) Vitamin K",
    },
    {
      question: "Which structure in the cell membrane is primarily responsible for its fluidity?",
      options: ["a) Proteins", "b) Phospholipids", "c) Cholesterol", "d) Carbohydrates"],
      correctAnswer: "c) Cholesterol",
    },
    {
      question: "What is the function of the lymphatic system?",
      options: ["a) Circulating blood", "b) Producing hormones", "c) Transporting lymph", "d) Excreting waste"],
      correctAnswer: "c) Transporting lymph",
    },
    {
      question: "Which molecule carries genetic information from DNA to the ribosome?",
      options: ["a) mRNA", "b) tRNA", "c) rRNA", "d) miRNA"],
      correctAnswer: "a) mRNA",
    },
    {
      question: "What is the main function of stomata in plants?",
      options: ["a) Absorbing nutrients", "b) Gas exchange", "c) Photosynthesis", "d) Water transport"],
      correctAnswer: "b) Gas exchange",
    },
    {
      question: "Which type of enzyme breaks down proteins into amino acids?",
      options: ["a) Lipase", "b) Amylase", "c) Protease", "d) Nuclease"],
      correctAnswer: "c) Protease",
    },
    {
      question: "Which phase of mitosis involves the separation of sister chromatids?",
      options: ["a) Prophase", "b) Metaphase", "c) Anaphase", "d) Telophase"],
      correctAnswer: "c) Anaphase",
    },
    {
      question: "Which organism is considered a prokaryote?",
      options: ["a) Yeast", "b) Bacteria", "c) Algae", "d) Protozoa"],
      correctAnswer: "b) Bacteria",
    },
  ],
},
// Add other subjects similarly...
};

const QuestionDetail = () => {
  const { questionId } = useParams();
  const questionSet = questions[questionId];
  const [checkedAnswers, setCheckedAnswers] = useState(Array(questionSet?.content.length).fill(false));

  if (!questionSet) {
    return <p>Question not found!</p>;
  }

  const handleCheckAnswer = (index) => {
    const updatedCheckedAnswers = [...checkedAnswers];
    updatedCheckedAnswers[index] = !updatedCheckedAnswers[index]; // Toggle the value
    setCheckedAnswers(updatedCheckedAnswers);
  };

  return (
    <>
      <Helmet>
        <title>{questionSet.title}</title>
        <meta name="description" content={`Find the most challenging questions for ${questionSet.title}. Prepare and test your knowledge.`} />
      </Helmet>
      <Container>
        <QuestionTitle>{questionSet.title}</QuestionTitle>
        {questionSet.content.map((q, index) => (
          <QuestionContent key={index}>
            <QuestionParagraph>{q.question}</QuestionParagraph>
            <QuestionList>
              {q.options.map((option, i) => (
                <li key={i}>{option}</li>
              ))}
            </QuestionList>
            <CheckButton onClick={() => handleCheckAnswer(index)}>
              {checkedAnswers[index] ? 'Hide Answer' : 'Check Answer'}
            </CheckButton>
            {checkedAnswers[index] && <CorrectAnswer>Correct Answer: {q.correctAnswer}</CorrectAnswer>}
          </QuestionContent>
        ))}
        <Check>Also Check</Check>
        <ul>
          <li><Link to="/questions/2" style={{color: "red", fontSize: "1.5em", fontWeight: "bold"}}>Chemistry Most Challenging Questions</Link></li>
          <li><Link to="/questions/3" style={{color: "red", fontSize: "1.5em", fontWeight: "bold"}}>Biology Most Challenging Questions</Link></li>
          <li><Link to="/questions/1" style={{color: "red", fontSize: "1.5em", fontWeight: "bold"}}>Physics Most Challenging Questions</Link></li>
          <li><Link to="/guide" style={{color: "red", fontSize: "1.5em", fontWeight: "bold"}}>How to Score 180+ Marks in MDCAT 2024</Link></li>
          <li><Link to="/phyguide" style={{color: "red", fontSize: "1.5em", fontWeight: "bold"}}>Physics For MDCAT</Link></li>
          <li><Link to="/chemguide" style={{color: "red", fontSize: "1.5em", fontWeight: "bold"}}>Chemistry For MDCAT</Link></li>
          <li><Link to="/bioguide" style={{color: "red", fontSize: "1.5em", fontWeight: "bold"}}>Biology For MDCAT</Link></li>
        </ul>
      </Container>
    </>
  );
};

export default QuestionDetail;