import React from 'react';
import styled from 'styled-components';

// Styled components for better UI
const Container = styled.div`
  padding: 20px;
  margin: 20px auto;
  max-width: 800px;
  background: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #333;
`;

const Formula = styled.p`
  font-size: 18px;
  color: #555;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PhyImpFormula = () => {
  return (
    <Container>
      <Section>
        <Title>Force and Motion</Title>
        <Formula>Newton's First Law: An object remains in uniform motion unless acted upon by an external force.</Formula>
      </Section>
    </Container>
  );
};

export default PhyImpFormula;
