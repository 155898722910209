import React, { useState, useEffect, useRef } from 'react';
import { Container, Box, Radio, RadioGroup, FormControlLabel, Button, Typography, LinearProgress, Tooltip } from '@mui/material';
import questionsData from './questions.json';
import styled from 'styled-components';

const QuestionBox = styled(Box)`
  color: ${({ theme }) => theme.text || '#ffffff'};
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: ${({ theme }) => theme.background || '#424242'};
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  background-color: #1976d2;
  color: white;
  &:hover {
    background-color: #115293;
  }
`;

const TimerBox = styled(Box)`
  color: ${({ theme }) => theme.text || '#ffffff'};
  margin-top: 20px;
  font-size: 18px;
`;

const ResultsBox = styled(Box)`
  color: ${({ theme }) => theme.text || '#ffffff'};
  margin-top: 40px;
`;

const IncorrectAnswerBox = styled(Box)`
  margin-top: 20px;
  background: white;
  padding: 10px;
  border-radius: 8px;
`;

const TopicSelector = styled(Box)`
  margin-bottom: 20px;
`;

const TestComponent = () => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [progress, setProgress] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState([]);
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutes
  const [selectedTopic, setSelectedTopic] = useState('Force and Motion');
  const timerRef = useRef(null);
  const resultsRef = useRef(null);

  useEffect(() => {
    if (questionsData && Array.isArray(questionsData)) {
      const filteredQuestions = questionsData.find(
        (q) => q.topic === selectedTopic
      );
      if (filteredQuestions && filteredQuestions.questions) {
        setQuestions(filteredQuestions.questions);
      }
    }
  }, [selectedTopic]);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          handleSubmit();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, []);

  const handleAnswerChange = (questionId, answer) => {
    setAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers, [questionId]: answer };
      setProgress((Object.keys(newAnswers).length / questions.length) * 100);
      return newAnswers;
    });
  };

  const handleSubmit = () => {
    const correctAnswers = questions.filter(
      (q) => answers[q.id] === q.correctAnswer
    ).length;
    const incorrectAnswers = questions.filter(
      (q) => answers[q.id] !== q.correctAnswer
    ).map((q) => ({
      question: q.question,
      selectedAnswer: answers[q.id],
      correctAnswer: q.correctAnswer,
    }));

    setCorrectCount(correctAnswers);
    setIncorrectAnswers(incorrectAnswers);
    setShowResults(true);
    clearInterval(timerRef.current);
    setTimeout(() => {
      if (resultsRef.current) {
        resultsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <main>
      <Container>
        <Typography variant="h4" gutterBottom color="red">
          Test
        </Typography>
        <TopicSelector>
          <Typography variant="h6" color="white">Select Topic:</Typography>
          <RadioGroup
            row
            value={selectedTopic}
            onChange={(e) => setSelectedTopic(e.target.value)}
          >
            {questionsData && Array.isArray(questionsData) && questionsData.length > 0 ? (
              questionsData.map((topic, index) => (
                <FormControlLabel
                  key={index}
                  value={topic.topic}
                  control={<Radio />}
                  label={topic.topic}
                  style={{ color: '#ffffff' }} // Ensuring visibility in dark theme
                />
              ))
            ) : (
              <Typography color="white">No topics available</Typography>
            )}
          </RadioGroup>
        </TopicSelector>
        <Typography variant="h6" gutterBottom color="primary">
          {selectedTopic}
        </Typography>
        <LinearProgress variant="determinate" value={progress} />
        {questions.length > 0 ? (
          questions.map((question) => (
            <QuestionBox key={question.id}>
              <Typography variant="h6">{question.question}</Typography>
              <RadioGroup
                name={`question-${question.id}`}
                onChange={(e) => handleAnswerChange(question.id, e.target.value)}
              >
                {question.options.map((option, index) => (
                  <Tooltip key={index} title={`Option ${index + 1}`} arrow>
                    <FormControlLabel
                      value={option}
                      control={<Radio />}
                      label={option}
                      style={{ color: '#ffffff' }} // Ensuring visibility in dark theme
                    />
                  </Tooltip>
                ))}
              </RadioGroup>
            </QuestionBox>
          ))
        ) : (
          <Typography color="white">No questions available</Typography>
        )}
        <SubmitButton variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </SubmitButton>
        {showResults && (
          <ResultsBox ref={resultsRef}>
            <Typography variant="h5">
              You got {correctCount} out of {questions.length} correct!
            </Typography>
            <Box mt={2}>
              <Typography variant="h6">Incorrect Answers:</Typography>
              {incorrectAnswers.map((item, index) => (
                <IncorrectAnswerBox key={index}>
                  <Typography variant="subtitle1" color="black">{item.question}</Typography>
                  <Typography variant="body1" color="error">
                    Your answer: {item.selectedAnswer}
                  </Typography>
                  <Typography variant="body1" color="primary">
                    Correct answer: {item.correctAnswer}
                  </Typography>
                </IncorrectAnswerBox>
              ))}
            </Box>
          </ResultsBox>
        )}
      </Container>
    </main>
  );
};

export default TestComponent;
