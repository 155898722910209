import React, { forwardRef, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { FaDownload, FaFlag, FaShareAlt } from "react-icons/fa";
import Modal from "react-modal";
import { addVideoToHistory } from "../redux/historySlice";

const Container = styled.article`
  width: ${(props) => (props.type !== "sm" ? "360px" : "auto")};
  margin-bottom: ${(props) => (props.type === "sm" ? "10px" : "45px")};
  cursor: pointer;
  display: ${(props) => (props.type === "sm" ? "flex" : "block")};
  gap: 10px;
  position: relative;
  &:hover video {
    display: block;
  }
`;

const Image = styled.img`
  width: 100%;
  height: ${(props) => (props.type === "sm" ? "120px" : "202px")};
  background-color: #999;
  flex: 1;
  display: block;
  position: relative;
`;

const VideoPreview = styled.video`
  display: none;
  width: ${(props) => (props.type === "sm" ? "auto" : "100%")};
  height: ${(props) => (props.type === "sm" ? "120px" : "202px")};
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const Details = styled.div`
  display: flex;
  margin-top: ${(props) => (props.type !== "sm" ? "16px" : "0")};
  gap: 12px;
  flex: 1;
  position: relative;
  z-index: 2;
`;

const Texts = styled.div``;

const Title = styled.h1`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.text};
`;

const MenuIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 2;
  font-size: 27px;
  color: black;
`;

const Menu = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: ${(props) => (props.open ? "block" : "none")};
  z-index: 10;
`;

const MenuItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const ShareContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const ShareButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor || "#ccc"};
  color: white;
  font-size: 20px;
  text-decoration: none;
`;

const Card = forwardRef(({ type, video }, ref) => {
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const videoRef = useRef(null);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play().catch(() => {
        // Handle play interruption if needed
      });
      timeoutRef.current = setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.pause();
        }
      }, 5000); // Play for 5 seconds
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  };

  const handleMenuToggle = (event) => {
    event.preventDefault();
    setMenuOpen((prev) => !prev);
  };

  const handleMenuItemClick = (action) => {
    console.log(action);
    if (action === "share") {
      setShareModalOpen(true);
    } else if (action === "download") {
      const link = document.createElement("a");
      link.href = video.videoUrl;
      link.download = `${video.title}.mp4`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (action === "report") {
      setReportModalOpen(true);
    }
    setMenuOpen(false);
  };

  const handleCloseModal = () => {
    setShareModalOpen(false);
    setReportModalOpen(false);
  };

  return (
    <Container
      type={type}
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => dispatch(addVideoToHistory(video._id))}
    >
      <Link to={`/video/${video._id}`} style={{ textDecoration: "none" }}>
        <Image type={type} src={video.imageUrl} alt="Video thumbnail" />
        <VideoPreview
          type={type}
          ref={videoRef}
          src={video.videoUrl}
          muted
          loop
        />
        <Details type={type}>
          <Texts>
            <Title>{video.title}</Title>
          </Texts>
          <MenuIcon onClick={handleMenuToggle}>&#x22ee;</MenuIcon>
          <Menu open={menuOpen}>
            <MenuItem onClick={() => handleMenuItemClick("share")}>
              <FaShareAlt /> Share
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("download")}>
              <FaDownload /> Download
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("report")}>
              <FaFlag /> Report
            </MenuItem>
          </Menu>
        </Details>
      </Link>
      <Modal isOpen={shareModalOpen} onRequestClose={handleCloseModal}>
        <h2>Share</h2>
        <p>Share this video:</p>
        <ShareContainer>
          <ShareButton
            bgColor="#3b5998"
            href={`https://www.facebook.com/sharer/sharer.php?u=https://youconect.com/video/${video._id}`}
            target="_blank"
          >
            F
          </ShareButton>
          <ShareButton
            bgColor="#00acee"
            href={`https://twitter.com/intent/tweet?url=https://youconect.com/video/${video._id}`}
            target="_blank"
          >
            T
          </ShareButton>
          <ShareButton
            bgColor="#25D366"
            href={`https://api.whatsapp.com/send?text=Check out this video: https://youconect.com/video/${video._id}`}
            target="_blank"
          >
            W
          </ShareButton>
        </ShareContainer>
        <button onClick={handleCloseModal}>Close</button>
      </Modal>
      <Modal isOpen={reportModalOpen} onRequestClose={handleCloseModal}>
        <h2>Report</h2>
        <p>Report this video:</p>
        <form onSubmit={(e) => e.preventDefault()}>
          <textarea rows="4" cols="50" placeholder="Describe the issue..." />
          <button type="submit">Submit</button>
        </form>
        <button onClick={handleCloseModal}>Close</button>
      </Modal>
    </Container>
  );
});

export default Card;
