import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactUs.css';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    emailjs.send('service_hpsqnxm', 'template_cncucq7', formData, '2pHvT-LtShz1Ricbk')
      .then((result) => {
        setResponseMessage('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' });
      }, (error) => {
        setResponseMessage('An error occurred while sending the message.');
      });
  };

  return (
    <main className="contact-us">
      <Helmet>
        <title>Contact Us | YouConect - Get in Touch with Us</title>
        <meta name="description" content="Contact YouConect for any inquiries Regarding Mdcat Or Website." />
      </Helmet>
      <div className="container">
        <h1>Contact Us</h1>
        <p>Hi! We’d like to hear from you. Fill the following information and we will get back to you as soon as possible, I will get back to you As soon as possibel</p>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" className="submit-btn">Send Message</button>
        </form>
        {responseMessage && <p className="response-message">{responseMessage}</p>}
      </div>
    </main>
  );
};

export default ContactUs;
