import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import videoData from '../components/videoData';

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 700px) {
    padding: 20px 0px;
  }
`;

const ResponsiveVideo = styled.video`
  width: 100%;
  height: 100vh;
  border-radius: 10px;

  @media (max-width: 768px) {
    border-radius: 5px;
  }
`;

const ResponsiveIframe = styled.iframe`
  width: 100%;
  height: 450px;
  border-radius: 10px;
  border: none;

  @media (max-width: 768px) {
  max-height: 400px;
  width: 70vw;
  object-fit: cover;
  }
`;

const VideoTitle = styled.h1`
  color: ${({ theme }) => theme.text};
  font-size: 2rem;
  width: 100%;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const VideoDescription = styled.p`
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  width: 100%;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const FullDescription = styled.div`
  color: ${({ theme }) => theme.text};
  margin-top: 20px;
  width: 100%;
  text-align: left;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const VideoDetails = () => {
  const { id } = useParams();
  const video = videoData.staticVideos.find(video => video._id === id);
  const [fullDescription, setFullDescription] = useState('');

  useEffect(() => {
    if (video && video.descriptionFile) {
      fetch(`/description/${video.descriptionFile}`)
        .then(response => response.text())
        .then(text => setFullDescription(text))
        .catch(error => console.error('Error loading description:', error));
    }
  }, [video]);

  if (!video) {
    return <div>Video not found</div>;
  }

  const videoSchema = {
    "@context": "http://schema.org",
    "@type": "VideoObject",
    "name": video.title,
    "description": video.description,
    "thumbnailUrl": video.imageUrl,
    "uploadDate": "2024-07-14",
    "contentUrl": video.videoUrl,
    "embedUrl": video.videoUrl,
    "duration": "PT2M30S",  // Example duration, adjust accordingly
    "publisher": {
      "@type": "Organization",
      "name": "YouConect",
    }
  };

  return (
    <VideoContainer>
      <Helmet>
        <title>{video.title} - Your Site Name</title>
        <meta name="description" content={video.description} />
        <meta name="keywords" content="youconect, mdcat, youconect mdcat, MDcat preparation, free MDcat tests, MDcat study material, MDcat video lectures, MDcat notes, MDcat practice exams" />
        <script type="application/ld+json">{JSON.stringify(videoSchema)}</script>
      </Helmet>
      <VideoTitle>{video.title}</VideoTitle>
      {video.videoType === "local" ? (
        <ResponsiveVideo controls>
          <source src={video.videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </ResponsiveVideo>
      ) : (
        <ResponsiveIframe
          src={video.videoUrl}
          title={video.title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      )}
      <VideoDescription>{video.description}</VideoDescription>
      <FullDescription>
        <div dangerouslySetInnerHTML={{ __html: fullDescription }} />
      </FullDescription>
    </VideoContainer>
  );
};

export default VideoDetails;
