import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const theme = {
  light: {
    text: "#333",
    background: "#f9f9f9",
    primary: "#007BFF",
  },
  dark: {
    text: "#f9f9f9",
    background: "#333",
    primary: "#1E90FF",
  },
};

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: ${({ theme }) => theme.background};
  @media (max-width: 768px) {
    padding: 10px;
  }
  width: 100%;
  min-height: 100vh; // Ensure it takes the full height of the viewport
`;

const Title = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.text};
  font-size: 2em;
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: pink;
  color: ${({ theme }) => theme.text};
  padding: 15px;
  font-size: 1.2em;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: darken(${({ theme }) => theme.primary}, 10%);
  }
  @media (max-width: 768px) {
    font-size: 1em;
    padding: 10px;
  }
`;

const Answer = styled.div`
  background-color: lighten(${({ theme }) => theme.primary}, 20%);
  color: ${({ theme }) => theme.text};
  padding: 15px;
  font-size: 1em;
  margin-top: 5px;
  border-radius: 5px;
  border-left: 5px solid ${({ theme }) => theme.primary};
  transition: max-height 0.3s ease, padding 0.3s ease;
  overflow: hidden;
  @media (max-width: 768px) {
    font-size: 0.9em;
    padding: 10px;
  }
`;

const questionsAndAnswers = [
  {
    question: "How to access daily tests?",
    answer: (
      <>
        To access daily tests, go to the <Link to="/tests" style={{color: "red"}}>Tests</Link> section from the main menu. You can take the test of the day.
      </>
    ),
  },
  {
    question: "How to watch video lectures?",
    answer: (
      <>
        Visit the <Link to="/" style={{color: "red"}}>Home page</Link> to find various categories, browse, and watch available lectures. You can filter them by categories.
      </>
    ),
  },
  {
    question: "How to watch video lectures?",
    answer: (
      <>
        Visit the <Link to="/" style={{color: "red"}}>Home page</Link> to find various categories, browse, and watch available lectures. You can filter them by categories.
      </>
    ),
  },
  {
    question: "Where can i find more about this website?",
    answer: (
      <>
        Visit <Link to="/aboutus" style={{color: "red"}}>About Us</Link> page in the menuto see more about our goal and who are we.
      </>
    ),
  },
  {
    question: "What should I do if I encounter a technical issue?",
    answer: (
      <>
        Contact our support team through the <Link to="/contactus" style={{color: "red"}}>Contact Us</Link> page in the menu .
      </>
    ),
  },
  {
    question: "What should I do if miss the test?",
    answer: (
      <>
        Go to <Link to="/" style={{color: "red"}}>Home Page</Link> under category test you will finds pdf of tests that we have covered.
      </>
    ),
  },
  {
    question: "How to enable dark mode?",
    answer: (
      <>
        In the menu, click on the 'Dark Mode' option to toggle between light and dark modes.
      </>
    ),
  },
];

const Help = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleQuestionClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <ThemeProvider theme={theme.light}>
      <Container>
        <Title>Help & FAQ</Title>
        {questionsAndAnswers.map((qa, index) => (
          <div key={index}>
            <Question onClick={() => handleQuestionClick(index)}>
              <span>{qa.question}</span>
              {openIndex === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Question>
            {openIndex === index && <Answer>{qa.answer}</Answer>}
          </div>
        ))}
      </Container>
    </ThemeProvider>
  );
};

export default Help;
