// src/videoData.js
import video1Image from './images/video1.jpg';
import video2Image from './images/video2.jpg';
const videoData = {
  staticVideos: [
    {
      _id: "1",
      title: "Mdcat Biology Most Difficult And Hardest Questions 1",
      videoUrl: "https://www.youtube.com/embed/IQpsERqdKds?si=-_4TFZ_IAMSOaqZe",
      imageUrl: video1Image,
      description: "Mdcat Biology Most Difficult And Hardest Questions 1",
      tags: ["All", "PAST PAPERS"],
      category: "PastPapers",
      descriptionFile: "sample_youtube_video.md",
      videoType: "youtube",
    },
    {
      _id: "2",
      title: "Mdcat Biology Most Difficult And Hardest Questions 2",
      videoUrl: "https://www.youtube.com/embed/j5a60hyW1lw?si=-_4TFZ_IAMSOaqZe",
      imageUrl: video2Image,
      description: "Mdcat Biology Most Difficult And Hardest Questions 2",
      tags: ["All", "PAST PAPERS"],
      category: "PastPapers",
      descriptionFile: "sample_youtube_video1.md",
      videoType: "youtube",
    },
  ],
};

export default videoData;
