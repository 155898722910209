import React, { useState } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const FlashcardsContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-x: auto;
  right: 0;

  @media (max-width: 500px) {
    right: 50px;
  }
  @media (max-width: 390px) {
    right: 80px;
  }
`;

const Flashcard = styled.article`
  perspective: 1000px;
  margin: 20px 0;
  cursor: pointer;
`;

const FlashcardInner = styled.div`
  position: relative;
  width: 300px;
  height: 200px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: ${({ theme }) => theme.cardBackground};
  color: ${({ theme }) => theme.text};
  ${(props) => props.flipped && 'transform: rotateY(180deg);'}
`;

const FlashcardFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
`;

const FlashcardFront = styled(FlashcardFace)`
  background: ${({ theme }) => theme.cardBackground};
`;

const FlashcardBack = styled(FlashcardFace)`
  background: ${({ theme }) => theme.primary};
  color: #fff;
  transform: rotateY(180deg);
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
`;

const Button = styled.button`
  background: gray;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;

  &:hover {
    background: ${({ theme }) => theme.primaryHover};
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const ProgressIndicator = styled.div`
  margin: 10px 0;
  font-size: 1.2em;
  color: ${({ theme }) => theme.text};
`;

const ToggleButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background: blue;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;

  &:hover {
    background: ${({ theme }) => theme.primaryHover};
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const SectionHeading = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
  color: #ffffff;
  text-align: center;
  margin: 20px 0;
  padding: 15px 20px;
  background: linear-gradient(45deg, #1d976c, #93f9b9);
  background-size: 400% 400%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const FormulasContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
`;

const FormulaCard = styled.div`
  background: ${({ theme }) => theme.cardBackground};
  color: ${({ theme }) => theme.text};
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const FormulaText = styled.div`
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 10px;
`;

const FormulaName = styled.div`
  font-size: 1.2em;
  text-align: center;
  color: ${({ theme }) => theme.secondaryText};
`;
const LinksContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 100%;
`;

const NavLink = styled(Link)`
  background: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 1.5em;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;

  &:hover {
    background: #45a049;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;
const Flashcards = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);

  const flashcards = [
    { question: "The capacity of doing work is called?", answer: "Energy." },
    { question: "Atom is the smallest particle which is?", answer: "Indivisible." },
    { question: "Ohm is unit of?", answer: "Resistance." },
    { question: "Gravitation force of moon is?", answer: "1.62 m/s2." },
    { question: "Youngs modulus is property of?", answer: "Solid only." },
    { question: "Which of the following is used to control rods in nuclear fission?", answer: "Cadmium." },
    { question: "Potential energy means?", answer: "Due to position of object." },
    { question: "Rutherford in his atomic model could not explain behavior of?", answer: "Electrones." },
    { question: "The mass energy is outcome of", answer: "Special theory of relativvity." },
    { question: "Farad is unit of?", answer: "Capacitance." },
    { question: "Force applied on a body through the string is called?", answer: "Tension." },
    { question: "The speed of light will be minimum while passing through?", answer: "Glass." },
    { question: "A stone thrown from top of a tall building follows a path that is?", answer: "Parabolic." },
    { question: "With increase of pressure, the boiling point of any substance?", answer: "Increases." },
    { question: "When body is in motion what always changes?", answer: "Its position vector." },
    { question: "A body moving with uniform velocity what changes?", answer: "its displacement from origin changes." },
    { question: "When velocity time graph is a straight line parallel to time axis then?", answer: "acceleration is zero." },
    { question: "Instantaneous and average velocities become equal when body?", answer: "has zero acceleration." },
    { question: "Which law of motion is also called law of inertia?", answer: "1st law." },
    { question: "Inertia of an object is quantitative measure of its?", answer: "Mass." },
    { question: "3RD law of motion explains?", answer: "Existence of pair of forces in nature." },
    // Add more flashcards here
  ];

  const formulas = [
    { formula: "W = Fdcosθ", name: "Work By a Constant Force" },
    { formula: "F = ma", name: "Newton's Second Law" },
    { formula: "Wnet = ΔKE", name: "Work Kinetic Energy Theorem" },
    { formula: "KE = ½ mv2", name: "Kinetic Energy" },
    { formula: "PE = mgh", name: "Potential Energy" },
    { formula: "Potential Energy", name: "PE=½kx2" },
    { formula: "Pascal’s Law of Hydrostatic Pressure", name: "P = ρgh" },
    { formula: "Bernoulli’s Equation", name: "P + ½ρv2 + ρgh = constant" },
    { formula: "Ideal Gas Law", name: "PV = nRT" },
    { formula: "Boyle’s Law", name: "PV = constant, P1V1 = P2V2" },
    { formula: "Charles’ Law", name: "V/T = constant, V1/T1 = V2/T2" },
    { formula: "Avogadro’s Law", name: "V/n = constant, V1/n1 = V2/n2" },
    { formula: "Dalton’s Law of Partial Pressures", name: "PTotal = P1 + P2 …" },
    { formula: "Coulomb’s Law", name: "F = k∙(q1q2/r2)" },
    { formula: "Constant Current", name: "I = ΔQ/Δt" },
    { formula: "Ohm’s Law", name: "I = V/R" },
    { formula: "Resistivity", name: "ρ = R∙A/L" },
    { formula: "Photon Energy", name: "E = hf" },
    { formula: "Snell’s Law", name: "n1sinθ1 = n2sinθ2" },
    { formula: "Lens Equation", name: "1/f = 1/p + 1/q" },
    // Add more formulas here
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    setShowAnswer(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < flashcards.length - 1 ? prevIndex + 1 : prevIndex));
    setShowAnswer(false);
  };

  const toggleAnswer = () => {
    setShowAnswer((prevShowAnswer) => !prevShowAnswer);
  };

  return (
    <>
      <Helmet>
        <title>Physics Flashcards | MDCAT Preparation</title>
        <meta name="description" content="Physics flashcards for MDCAT preparation. Learn key concepts and improve your knowledge with our interactive flashcards." />
        <meta name="keywords" content="MDCAT, Physics, Flashcards, MDCAT Preparation, Learning, Physics Questions and Answers" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Physics Flashcards | MDCAT Preparation",
            "description": "Physics flashcards for MDCAT preparation. Learn key concepts and improve your knowledge with our interactive flashcards.",
            "mainEntity": {
              "@type": "CreativeWork",
              "name": "Physics Flashcards",
              "about": {
                "@type": "Thing",
                "name": "MDCAT Physics"
              },
              "educationalLevel": "High School",
              "isAccessibleForFree": "True",
              "learningResourceType": "Flashcards",
              "audience": {
                "@type": "Audience",
                "audienceType": "Students"
              },
              "keywords": "MDCAT, Physics, Flashcards, MDCAT Preparation, Learning, Physics Questions and Answers"
            }
          }
          `}
        </script>
      </Helmet>
      <FlashcardsContainer>
        <SectionHeading>
          Physics Flashcards
        </SectionHeading>
        <Flashcard>
          <FlashcardInner flipped={showAnswer}>
            <FlashcardFront>
              {flashcards[currentIndex].question}
            </FlashcardFront>
            <FlashcardBack>
              {flashcards[currentIndex].answer}
            </FlashcardBack>
          </FlashcardInner>
        </Flashcard>
        <ToggleButton onClick={toggleAnswer}>
          {showAnswer ? "Hide Answer" : "Show Answer"}
        </ToggleButton>
        <ProgressIndicator>
          {currentIndex + 1} / {flashcards.length}
        </ProgressIndicator>
        <NavigationButtons>
          <Button onClick={handlePrev} disabled={currentIndex === 0}>
            Previous
          </Button>
          <Button onClick={handleNext} disabled={currentIndex === flashcards.length - 1}>
            Next
          </Button>
        </NavigationButtons>

        <SectionHeading>
          Key Formulas
        </SectionHeading>
        <FormulasContainer>
          {formulas.map((item, index) => (
            <FormulaCard key={index}>
              <FormulaText>{item.formula}</FormulaText>
              <FormulaName>{item.name}</FormulaName>
            </FormulaCard>
          ))}
        </FormulasContainer>
        <LinksContainer>
          <NavLink to="/chemflashcard">Chemistry Flashcard</NavLink>
          <NavLink to="/bioflashcard">Biology Flashcard</NavLink>
        </LinksContainer>
      </FlashcardsContainer>
    </>
  );
};

export default Flashcards;
