import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.bgLighter};
  color: ${({ theme }) => theme.text};
  max-width: 800px;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 10px;
`;

const List = styled.ul`
  padding-left: 20px;
  margin-bottom: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const PrivacyPolicy = () => {
  return (
    <main>
    <Container>
      <Title>Privacy Policy</Title>
      <Paragraph>
        Welcome to YouConect. To ensure you of that we strictly adhere to our privacy policy. This privacy statement outlines the policy that this site adheres to in regard to the collection, usage, and sharing of information about you that you provide to us each time you visit our website. 
      </Paragraph>

      <SectionTitle>Information We Collect</SectionTitle>
      <Paragraph>We collect information in the following ways:</Paragraph>
      <List>
        <ListItem>
          <strong>Information You Provide:</strong> We receive and store any information you enter on our website or give us in any other way.
        </ListItem>
        <ListItem>
          <strong>Information We Collect Automatically:</strong> Like most websites, we operate an ‘automated collection’ of certain data that you give off when using the website – for example; your IP address, browser type, and operating system. 
        </ListItem>
      </List>

      <SectionTitle>The Usage of Collected Info</SectionTitle>
      <Paragraph>We use the information we collect to:</Paragraph>
      <List>
        <ListItem>Provide, maintain, and enhance our services;</ListItem>
        <ListItem>Communicate with you about our services, and events;</ListItem>
        <ListItem>analyze usage, and activities;</ListItem>
        <ListItem>Personalize the content and advertisements you see;</ListItem>
        <ListItem>Preventing fraudulent transactions as well as other unlawful activities and investigating them when they are reported.</ListItem>
      </List>

      <SectionTitle>Sharing Your Information</SectionTitle>
      <Paragraph>We may share your information with:</Paragraph>
      <List>
        <ListItem>
          <strong>Service Providers:</strong> We may share your information with third-party service who provide services to us, for instance, the provision of hosting services, data processing, among others, and marketing.
        </ListItem>
        <ListItem>
          <strong>Legal Requirements:</strong> All of your information is completely confidential with us but we may be forced by law or due to compliance with a legal proceeding to disclose some of this information.
        </ListItem>
      </List>

      <SectionTitle>Security</SectionTitle>
      <Paragraph>
      All possible steps are employed to protect your information from unauthorized access, theft, misuse or intentional or unintentional destruction, alteration or disclosure. 
      </Paragraph>

      <SectionTitle>Contact Us</SectionTitle>
      <Paragraph>
        If you have any questions about this privacy policy, please contact us at <a href="mailto:mudassir.muneer123@gmail.com" style={{color: "red"}}>mudassir.muneer123@gmail.com</a>.
      </Paragraph>
    </Container>
    </main>
  );
};

export default PrivacyPolicy;
