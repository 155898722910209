import { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import Menu from "./components/Menu";
import Navbar from "./components/Navbar";
import { darkTheme, lightTheme } from "./utils/Theme";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Video from "./pages/Video";
import QuestionDetail from './components/Questions/QuestionDetail';
import Guide from './components/Questions/Guide.jsx';
import SignIn from "./pages/SignIn";
import Search from "./pages/Search";
import { useSelector } from "react-redux";
import Profile from "./components/Profile";
import Help from "./components/menu pages/Help";
import Feedback from "./components/menu pages/Feedback";
import History from "./components/menu pages/History";
import Upload from "./components/Upload";
import AboutUs from "./components/menu pages/AboutUs";
import ContactUs from "./components/menu pages/ContactUs";
import PrivacyPolicy from './components/menu pages/PrivacyPolicy';
import TestComponent from './components/test/TestComponent';
import PdfViewer from './components/test/PdfViewer'; // Adjust the import path as needed
import BioGuide from './components/Questions/BioGuide'; 
import PhyGuide from './components/Questions/PhyGuide'; 
import ChemGuide from './components/Questions/ChemGuide.jsx'; 
import BioFlashcards from './components/test/BioFlashcards.jsx'; 
import ChemFlashcards from './components/test/ChemFlashcards.jsx'; 
import PhyFlashcards from './components/test/PhyFlashcards.jsx'; 
import PhyImpFormula from './components/Questions/PhyImpFormula.jsx'
import EngGuide from './components/Questions/EngGuide.jsx'

// will add later
//import { ThreadsApp } from "./threads/src/main";
//import { ShortsApp } from "./shorts/src/index";

const Container = styled.div`
  display: flex;
  overflow-x: hidden;
`;

const Main = styled.div`
  flex: 7;
  background-color: ${({ theme }) => theme.bg};
`;
const Wrapper = styled.div`
  padding: 22px 96px;
`;

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const { currentUser } = useSelector((state) => state.user);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Container>
        <BrowserRouter>
          <Menu darkMode={darkMode} setDarkMode={setDarkMode} />
          <Main>
            <Navbar />
            <Wrapper>
              <Routes>
                <Route path="/">
                  <Route index element={<Home type="random" />} />
                  <Route path="/help" element={<Help />} />
                  <Route path="/contactus" element={<ContactUs />} />
                  <Route path="/aboutus" element={<AboutUs />} />
                  <Route path="/feedback" element={<Feedback />} />
                  <Route path="/history" element={<History />} />
                  <Route path="/upload-video" element={<Upload />} />
                  <Route path="/pdf/:id" element={<PdfViewer />} />
                  <Route path="/questions/:questionId" element={<QuestionDetail />} />
                  <Route path="/guide" element={<Guide />} />
                  <Route path="subscriptions" element={<Home type="sub" />} />
                  <Route path="/phyguide" element={<PhyGuide />} />
                  <Route path="/chemguide" element={<ChemGuide />} />
                  <Route path="/bioguide" element={<BioGuide />} />
                  <Route path="search" element={<Search />} />
                  <Route path="/bioflashcard" element={<BioFlashcards />} />
                  <Route path="/phyflashcard" element={<PhyFlashcards />} />
                  <Route path="/chemflashcard" element={<ChemFlashcards />} />
                  <Route path="/engguide" element={<EngGuide />} />
                  <Route path="/tests" element={<TestComponent />} />
                  <Route path="/profile/:userId" element={<Profile />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/phyimpformula" element={<PhyImpFormula />} />
                  <Route
                    path="signin"
                    element={currentUser ? <Home /> : <SignIn />}
                  />
                  <Route path="/video/:id" element={<Video />} />
                </Route>
              </Routes>
            </Wrapper>
          </Main>
        </BrowserRouter>
      </Container>
    </ThemeProvider>
  );
}

export default App;
