import React from 'react';

const StudyGuide = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: 'auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
      color: '#333',
      background: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    title: {
      fontSize: '2.5em',
      color: '#2c3e50',
      textAlign: 'center',
      marginBottom: '20px',
    },
    intro: {
      fontSize: '1.2em',
      marginBottom: '20px',
      textAlign: 'justify',
    },
    sectionTitle: {
      fontSize: '1.8em',
      color: '#2980b9',
      marginBottom: '10px',
    },
    list: {
      listStyleType: 'disc',
      paddingLeft: '20px',
      marginBottom: '20px',
    },
    listItem: {
      marginBottom: '10px',
    },
    conclusion: {
      background: '#ecf0f1',
      padding: '15px',
      borderRadius: '8px',
    },
    conclusionText: {
      fontSize: '1.2em',
      textAlign: 'justify',
    },
    link: {
      color: '#2980b9',
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    linkHover: {
      textDecoration: 'underline',
    },
    linksContainer: {
      marginTop: '40px',
      textAlign: 'center',
    },
    linksTitle: {
      fontSize: '1.8em',
      color: '#2c3e50',
      marginBottom: '20px',
    },
    linkButton: {
      display: 'inline-block',
      padding: '10px 20px',
      margin: '10px',
      color: '#fff',
      backgroundColor: '#2980b9',
      borderRadius: '5px',
      textDecoration: 'none',
      transition: 'background-color 0.3s',
    },
    linkButtonHover: {
      backgroundColor: '#1f5a78',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Mastering Physics for MDCAT: Key Concepts and Practice Tips</h1>
      <p style={styles.intro}>
        Physics is a crucial subject in the MDCAT exam, and mastering it can significantly boost your overall score. This guide will help you understand key concepts and provide practice tips to excel in the Physics section of MDCAT 2024.
      </p>

      <section>
        <h2 style={styles.sectionTitle}>1. Key Concepts</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Force and Motion: Understand Newton's laws, kinematics, and dynamics.</li>
          <li style={styles.listItem}>Work and Energy: Study work-energy theorem and conservation of energy.</li>
          <li style={styles.listItem}>Rotational and Circular Motion: Learn angular kinematics, torque, and moment of inertia.</li>
          <li style={styles.listItem}>Waves: Focus on properties of waves, sound, and light.</li>
          <li style={styles.listItem}>Thermodynamics: Understand the laws of thermodynamics and heat transfer.</li>
          <li style={styles.listItem}>Electrostatics: Study Coulomb's law, electric field, and potential.</li>
          <li style={styles.listItem}>Current Electricity: Learn Ohm's law, circuit analysis, and Kirchhoff's laws.</li>
          <li style={styles.listItem}>Electromagnetism: Understand magnetic fields and electromagnetic induction.</li>
          <li style={styles.listItem}>Electronics: Study semiconductors, diodes, transistors, and logic gates.</li>
          <li style={styles.listItem}>Modern Physics: Learn quantum mechanics, atomic spectra, and nuclear physics.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>2. Creating a Study Plan</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}><strong>Daily Study Sessions:</strong> Dedicate 2-3 hours daily to physics, breaking it down into focused study blocks.</li>
          <li style={styles.listItem}><strong>Weekly Goals:</strong> Set specific topics to cover each week. For example, week 1 could focus on force and motion, while week 2 could focus on thermodynamics.</li>
          <li style={styles.listItem}><strong>Regular Revision:</strong> Allocate time each week for revising previously covered topics to reinforce your memory.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>3. Study Resources</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}><strong>Textbooks:</strong> Use recommended textbooks such as Halliday, Resnick, and Walker's Fundamentals of Physics or relevant local publications.</li>
          <li style={styles.listItem}><strong>Online Resources:</strong> Access free study materials, video lectures, and interactive quizzes on our website.</li>
          <li style={styles.listItem}><strong>Past Papers:</strong> Practice with past MDCAT papers available for free on our website to familiarize yourself with the exam format and question types.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>4. Effective Study Techniques</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}><strong>Active Learning:</strong> Engage in active learning by summarizing topics in your own words, teaching concepts to a peer, or creating mind maps.</li>
          <li style={styles.listItem}><strong>Practice Questions:</strong> Solve a variety of practice questions to test your understanding and improve your problem-solving skills.</li>
          <li style={styles.listItem}><strong>Flashcards:</strong> Use flashcards for quick revision of key terms and concepts.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>5. Focus on High-Yield Topics</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}><strong>Force and Motion:</strong> Ensure a thorough understanding of Newton's laws and kinematics.</li>
          <li style={styles.listItem}><strong>Thermodynamics:</strong> Focus on the laws of thermodynamics and heat transfer mechanisms.</li>
          <li style={styles.listItem}><strong>Electromagnetism:</strong> Study magnetic fields, Faraday's law, and Lenz's law.</li>
          <li style={styles.listItem}><strong>Modern Physics:</strong> Cover the photoelectric effect, wave-particle duality, and atomic spectra in detail.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>6. Practice and Review</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}><strong>Weekly Tests:</strong> Take our free weekly tests to assess your progress and identify areas needing improvement.</li>
          <li style={styles.listItem}><strong>Mock Exams:</strong> Simulate the actual exam conditions with full-length mock exams available on our website.</li>
          <li style={styles.listItem}><strong>Feedback:</strong> Review your test results to understand your mistakes and learn from them.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>7. Stay Healthy and Motivated</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}><strong>Balanced Diet:</strong> Eat a nutritious diet to keep your energy levels high.</li>
          <li style={styles.listItem}><strong>Exercise:</strong> Engage in regular physical activity to reduce stress.</li>
          <li style={styles.listItem}><strong>Rest:</strong> Ensure you get adequate sleep and take regular breaks during study sessions.</li>
        </ul>
      </section>

      <section style={styles.conclusion}>
        <h2 style={styles.sectionTitle}>Conclusion</h2>
        <p style={styles.conclusionText}>
          Scoring high in the MDCAT Physics section requires a strategic approach, consistent effort, and the right resources. By following this comprehensive study guide, you can effectively prepare for the exam and achieve your goal. Visit <a href="https://www.youconect.com/" target="_blank" rel="noopener noreferrer" style={styles.link} onMouseOver={(e) => e.currentTarget.style.textDecoration = styles.linkHover.textDecoration} onMouseOut={(e) => e.currentTarget.style.textDecoration = 'none'}>YouConect</a> for more resources, free past papers, and weekly tests to aid your preparation. Stay focused, stay motivated, and success will be within your reach!
        </p>
      </section>
      <div style={styles.linksContainer}>
        <h2 style={styles.linksTitle}>Explore More Resources</h2>
        <a 
          href="/questions/1" 
          style={styles.linkButton} 
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.linkButtonHover.backgroundColor} 
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.linkButton.backgroundColor}
        >
          Physics Challenging Questions
        </a>
        <a 
          href="/tests" 
          style={styles.linkButton} 
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.linkButtonHover.backgroundColor} 
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.linkButton.backgroundColor}
        >
          Mdcat Tests
        </a>
        <a 
          href="/bioguide" 
          style={styles.linkButton} 
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.linkButtonHover.backgroundColor} 
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.linkButton.backgroundColor}
        >
          Biology For MDCAT
        </a>
        <a 
          href="/chemguide" 
          style={styles.linkButton} 
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.linkButtonHover.backgroundColor} 
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.linkButton.backgroundColor}
        >
          Chemistry For MDCAT
        </a>
      </div>
    </div>
  );
};

export default StudyGuide;
