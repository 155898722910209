import React from 'react';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 20px;
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const SubTitle = styled.h2`
  font-size: 2rem;
  color: #0056b3;
  margin-bottom: 10px;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.text};
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;

  &:before {
    content: '✓';
    position: absolute;
    left: 0;
    color: #28a745;
    font-weight: bold;
  }
`;

const Link = styled.a`
  color: red;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const P = styled.p`
  color: ${({ theme }) => theme.text};
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

const Guide = () => {
  return (
    <Container>
      <Title>How to Score 180+ Marks in MDCAT 2024: A Step-by-Step Guide</Title>
      <P>
        Preparing for the MDCAT (Medical & Dental College Admission Test) is a crucial step for aspiring medical and dental students. With the right strategy and resources, you can achieve your goal of scoring 180+ marks. Our website offers free past papers, weekly tests, and free questions to aid your preparation. Here’s a comprehensive guide to help you ace the MDCAT 2024.
      </P>

      <Section>
        <SubTitle>1. Understand the MDCAT Syllabus</SubTitle>
        <List>
          <ListItem>Biology: Cover topics such as Biodiversity, Biological Molecules, Cell Structure, Enzymes, etc. visit <Link href="/questions/3">Biology Most Challenging Questions </Link> </ListItem>
          <ListItem>Physics: Focus on Force and Motion, Thermodynamics, Electromagnetism, and other key topics. visit <Link href="/questions/1">Physics Most Challenging Questions </Link></ListItem>
          <ListItem>Chemistry: Ensure a thorough understanding of Organic, Inorganic, and Physical Chemistry. visit <Link href="/questions/2">Chemistry Most Challenging Questions </Link></ListItem>
          <ListItem>English: Enhance your grammar, vocabulary, and comprehension skills.</ListItem>
          <ListItem>Logical Reasoning: Practice problem-solving and critical thinking questions.</ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>2. Create a Study Plan</SubTitle>
        <List>
          <ListItem>Daily Study Schedule: Dedicate specific hours each day to different subjects.</ListItem>
          <ListItem>Weekly Goals: Set weekly targets for covering certain topics and completing tests.</ListItem>
          <ListItem>Regular Breaks: Include short breaks in your study sessions to avoid burnout.</ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>3. Use Quality Study Materials</SubTitle>
        <List>
          <ListItem>Textbooks: Refer to recommended textbooks for detailed explanations.</ListItem>
          <ListItem>Notes: Use concise and clear notes to review important concepts.</ListItem>
          <ListItem>Past Papers: Practice with past papers available for free on our website.</ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>4. Practice, Practice, Practice</SubTitle>
        <List>
          <ListItem>Weekly Tests: Take our free weekly tests to track your progress and identify weak areas. visit <Link href="/tests">Test (To Solve 2 Tests Weekly)</Link></ListItem>
          <ListItem>Mock Exams: Simulate exam conditions with full-length mock exams.</ListItem>
          <ListItem>Timed Practice: Practice questions under timed conditions to improve your speed and accuracy.</ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>5. Focus on High-Yield Topics</SubTitle>
        <List>
          <ListItem>Identify and prioritize topics that are frequently tested.</ListItem>
          <ListItem>Allocate more time to complex topics that require deeper understanding.</ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>6. Revise Regularly</SubTitle>
        <List>
          <ListItem>Review Notes: Regularly go over your notes to reinforce your memory.</ListItem>
          <ListItem>Summarize: Create summaries of key concepts for quick revision.</ListItem>
          <ListItem>Flashcards: Use flashcards for quick recall of important information.</ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>7. Improve Test-Taking Strategies</SubTitle>
        <List>
          <ListItem>Time Management: Learn to manage your time effectively during the exam.</ListItem>
          <ListItem>Question Analysis: Carefully read and analyze questions before answering.</ListItem>
          <ListItem>Elimination Method: Use the elimination method to narrow down multiple-choice options.</ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>8. Stay Healthy and Positive</SubTitle>
        <List>
          <ListItem>Balanced Diet: Eat nutritious food to keep your mind and body healthy.</ListItem>
          <ListItem>Exercise: Engage in regular physical activity to reduce stress.</ListItem>
          <ListItem>Rest: Ensure you get adequate sleep and rest.</ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>9. Seek Support and Guidance</SubTitle>
        <List>
          <ListItem>Mentorship: Seek guidance from teachers, mentors, and successful MDCAT candidates.</ListItem>
          <ListItem>Peer Study Groups: Join study groups to share knowledge and resources.</ListItem>
          <ListItem>Online Forums: Participate in online forums and communities for additional support.</ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>10. Utilize Our Free Resources</SubTitle>
        <List>
          <ListItem>Past Papers: Access free past papers to familiarize yourself with the exam pattern.</ListItem>
          <ListItem>Weekly Tests: Take advantage of our free weekly tests to assess your preparation.</ListItem>
          <ListItem>Free Questions: Practice with our extensive collection of free questions to hone your skills.</ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>Links To Ace Mdcat</SubTitle>
          <li><a href="/pdf/3" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 1 -2</a></li>
          <li><a href="/pdf/4" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 2</a></li>
          <li><a href="/pdf/9" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 3</a></li>
          <li><a href="/pdf/11" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 4</a></li>
          <li><a href="/pdf/7" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Physics - Unit 2 </a></li>
          <li><a href="/pdf/8" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Physics - Unit 3</a></li>
          <li><a href="/pdf/6" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 2</a></li>
          <li><a href="/pdf/10" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 3</a></li>
          <li><a href="/pdf/12" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 4</a></li>     
      </Section>

      <Section>
        <SubTitle>Conclusion</SubTitle>
        <P>
          Scoring 180+ in MDCAT 2024 is achievable with the right approach and dedication. Utilize the resources available on our website, follow this step-by-step guide, and stay committed to your goal. With consistent effort and smart study techniques, you can excel in the MDCAT and secure a place in your desired medical or dental college.
        </P>
        <P>
          Visit <Link href="https://www.youconect.com/">YouConect </Link> for more resources and start your journey towards MDCAT success today!
        </P>
      </Section>
    </Container>
  );
};

export default Guide;
