import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Check = styled.h2`
  font-size: 2em;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;

const pdfData = [
    {
      id: 1,
      topicName: "Physics - Unit 1",
      description: "Forces And Motion. Physics is one of the subjects that guides people in understanding the forces that covers the laws of nature and Unit 1: Force and Motion is the foundation in that learning process. This unit consists of such critical topics as Newton’s Laws of Motion, Kinematics, Dynamics etc. The knowledge and skills of how an object can move and or can be made to move under different forces will be gained once you have attempted the MCQs contained in this PDF in attempt to solve them. These questions are set out to test your understanding and ability to apply theoretical concepts to solve real life scenarios. This unit should enable you in preparation for the MDCAT and yield a solid foundation for future more study in physics and engineering.",
      pdfUrl: "/pdf/force-motion.pdf",
      extraLinks: [
        <><Check>View More Related Pdfs</Check><ul>
          <li><Link to="/pdf/7" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold", }}>Physics - Unit 2 </Link></li>
          <li><Link to="/pdf/8" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Physics - Unit 3</Link></li>
          <li><Link to="/pdf/14" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Physics - Unit 4</Link></li>
        </ul></>
      ],
    },
    {
      id: 2,
      topicName: "Biology - Unit 1",
      description: "Cell structure and functions -1. Biology Unit 1 focuses on the matters that surround one of the most important structural of life, and that is cells. It is very important to know the structure, and functions of cells as an essential foundation for studying other advanced related concepts. The listed MCQs in this PDF covers matters like cell organelles, their roles, and cell operations like respiration and photosynthesis. These questions are designed in a manner that one is able to perform any analogous question given in the MDCAT with ease The provided questions include the following: Performing well in this unit is likely to result in improved overall performance in this subject as well as provide grounding for higher level concepts in medicine and other life sciences.",
      pdfUrl: "/pdf/cells.pdf",
      extraLinks: [
        <><Check>View More Related Pdfs</Check><ul>
          <li><Link to="/pdf/3" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 1 -2</Link></li>
          <li><Link to="/pdf/4" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 2</Link></li>
          <li><Link to="/pdf/9" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 3</Link></li>
          <li><Link to="/pdf/11" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 4</Link></li>
        </ul></>
      ],
    },
    {
      id: 3,
      topicName: "Biology - Unit 1",
      description: "Cell structure and functions -2.",
      pdfUrl: "/pdf/cells2.pdf",
      extraLinks: [
        <><Check>View More Related Pdfs</Check><ul>
          <li><Link to="/pdf/2" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 1 -1</Link></li>
          <li><Link to="/pdf/4" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 2</Link></li>
          <li><Link to="/pdf/9" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 3</Link></li>
          <li><Link to="/pdf/11" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 4</Link></li>
        </ul></>
      ],
    },
    {
      id: 4,
      topicName: "Biology - Unit 2",
      description: "Biological Molecules.",
      pdfUrl: "/pdf/biological.pdf",
      extraLinks: [
        <><Check>View More Related Pdfs</Check><ul>
          <li><Link to="/pdf/2" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 1 -1</Link></li>
          <li><Link to="/pdf/3" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 1 -2</Link></li>
          <li><Link to="/pdf/9" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 3</Link></li>
          <li><Link to="/pdf/11" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 4</Link></li>
        </ul></>
      ],
    },
    {
      id: 5,
      topicName: "Chemistry - Unit 1",
      description: "Introduction to Fundamnental Concepts of Chemistry. Chemistry acts as the basis of comprehending matter and its behavior. In the first unit of the Chemistry we explore the fundamental concepts that are essential for any aspiring medical student. This unit is implemented on atomic structure, classification of the elements, chemical combination or bonding and the quantitative relationships between the amounts of reactants and products. These MCQs in this PDF are intended to help you review these basic concepts so that you can comfortably solve tough problems. This way, you will succeed in attaining high marks in the MDCAT chemistry portion – the foundation for an outstanding future in the sphere of medicine and allied specialties.",
      pdfUrl: "/pdf/fundamentalchemistry.pdf",
      extraLinks: [
        <><Check>View More Related Pdfs</Check><ul>
          <li><Link to="/pdf/6" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 2</Link></li>
          <li><Link to="/pdf/10" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 3</Link></li>
          <li><Link to="/pdf/12" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 4</Link></li>
          <li><Link to="/pdf/13" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 5</Link></li>
        </ul></>
      ],
    },
    {
      id: 6,
      topicName: "Chemistry - Unit 2",
      description: "Atomic Structure.",
      pdfUrl: "/pdf/atomic.pdf",
      extraLinks: [
        <><Check>View More Related Pdfs</Check><ul>
          <li><Link to="/pdf/5" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 1</Link></li>
          <li><Link to="/pdf/10" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 3</Link></li>
          <li><Link to="/pdf/12" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 4</Link></li>
          <li><Link to="/pdf/13" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 5</Link></li>
        </ul></>
      ],
    },
    {
      id: 7,
      topicName: "Physics - Unit 2",
      description: "Work And Energy.",
      pdfUrl: "/pdf/work-energy.pdf",
      extraLinks: [
        <><Check>View More Related Pdfs</Check><ul>
          <li><Link to="/pdf/1" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Physics - Unit 1</Link></li>
          <li><Link to="/pdf/8" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Physics - Unit 3</Link></li>
          <li><Link to="/pdf/14" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Physics - Unit 4</Link></li>
        </ul></>
      ],
    },
    {
      id: 8,
      topicName: "Physics - Unit 3",
      description: "Rotation And Circular Motion.",
      pdfUrl: "/pdf/rotation-circularmotion.pdf",
      extraLinks: [
        <><Check>View More Related Pdfs</Check><ul>
          <li><Link to="/pdf/7" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Physics - Unit 2</Link></li>
          <li><Link to="/pdf/1" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Physics - Unit 1</Link></li>
          <li><Link to="/pdf/14" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Physics - Unit 4</Link></li>
        </ul></>
      ],
    },
    {
      id: 9,
      topicName: "Biology - Unit 3",
      description: "Enzymes.",
      pdfUrl: "/pdf/enzymes.pdf",
      extraLinks: [
        <><Check>View More Related Pdfs</Check><ul>
          <li><Link to="/pdf/2" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 1 -1</Link></li>
          <li><Link to="/pdf/3" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 1 -2</Link></li>
          <li><Link to="/pdf/4" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 2</Link></li>
          <li><Link to="/pdf/11" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 4</Link></li>
        </ul></>
      ],
    },
    {
      id: 10,
      topicName: "Chemistry - Unit 3",
      description: "Chemical Bonding.",
      pdfUrl: "/pdf/chemical-bonding.pdf",
      extraLinks: [
        <><Check>View More Related Pdfs</Check><ul>
          <li><Link to="/pdf/5" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 1</Link></li>
          <li><Link to="/pdf/6" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 2</Link></li>
          <li><Link to="/pdf/12" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 4</Link></li>
          <li><Link to="/pdf/13" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 5</Link></li>
        </ul></>
      ],
    },
    {
      id: 11,
      topicName: "Biology - Unit 4",
      description: "Acellular life biodiversity/viruses.",
      pdfUrl: "/pdf/viruses.pdf",
      extraLinks: [
        <><Check>View More Related Pdfs</Check><ul>
          <li><Link to="/pdf/2" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 1 -1</Link></li>
          <li><Link to="/pdf/3" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 1 -2</Link></li>
          <li><Link to="/pdf/4" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 2</Link></li>
          <li><Link to="/pdf/9" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Biology - Unit 3</Link></li>
        </ul></>
      ],
    },
    {
      id: 12,
      topicName: "Chemistry - Unit 4",
      description: "Gases.",
      pdfUrl: "/pdf/gases.pdf",
      extraLinks: [
        <><Check>View More Related Pdfs</Check><ul>
          <li><Link to="/pdf/5" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 1</Link></li>
          <li><Link to="/pdf/6" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 2</Link></li>
          <li><Link to="/pdf/10" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 3</Link></li>
          <li><Link to="/pdf/13" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 5</Link></li>
        </ul></>
      ],
    },
    {
      id: 12,
      topicName: "Random - Questions",
      description: "Test of random quetions we have done till now.",
      pdfUrl: "/pdf/random.pdf",
      extraLinks: [
        <><Check>View More Related Pdfs</Check><ul>
          <li><Link to="/pdf/7" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Physics - Unit 2</Link></li>
          <li><Link to="/pdf/8" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Physics - Unit 3</Link></li>
          <li><Link to="/pdf/14" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Physics - Unit 4</Link></li>
        </ul></>
      ],
    },
    {
      id: 13,
      topicName: "Chemistry - Unit 5",
      description: "Liquids.",
      pdfUrl: "/pdf/liquid.pdf",
      extraLinks: [
        <><Check>View More Related Pdfs</Check><ul>
          <li><Link to="/pdf/5" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 1</Link></li>
          <li><Link to="/pdf/6" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 2</Link></li>
          <li><Link to="/pdf/10" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 3</Link></li>
          <li><Link to="/pdf/13" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Chemistry - Unit 5</Link></li>
        </ul></>
      ],
    },
    {
      id: 14,
      topicName: "Physics - Unit 4",
      description: "Waves.",
      pdfUrl: "/pdf/rotation-circularmotion.pdf",
      extraLinks: [
        <><Check>View More Related Pdfs</Check><ul>
          <li><Link to="/pdf/7" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Physics - Unit 2</Link></li>
          <li><Link to="/pdf/1" style={{ color: "green", fontSize: "1.2em", fontWeight: "bold" }}>Physics - Unit 1</Link></li>
        </ul></>
      ],
    },
    // Add more PDF data as needed
  ];
  
  export default pdfData;
  