// AboutUs.jsx
import React from 'react';
import { Helmet } from 'react-helmet';
import './AboutUs.css';
import aboutUsImage from "../../img/chat.png";

const AboutUs = () => {
  return (
    <main className="about-us">
      <Helmet>
        <title>About Us | YouConect - Best Wesite For Free MDCAT Preparation </title>
        <meta name="description" content="Learn more about YouConect, our mission, and how we help students excel in their MDCAT exams with high-quality resources and personalized support." />
      </Helmet>
      <div className="container">
        <h1>About Us</h1>
        <div className="about-content">
          <div className="about-text">
            <p>
              Welcome to YouConect, your most preferred source of MDCAT preparation. 
              Our mission is to provide a comprehensive platform for students to succeed on their exams through high-quality content, practice tests, and study materials.
            </p>
            <p>
              Our team is dedicated to creating a supportive and enjoyable learning environment. With our real-time assessment, 
              personalized curriculum and expert guidance, we ensure that every student has the tools they need to succeed.
            </p>
            <p>
              At YouConect, we are committed to helping you achieve your learning goals. Every day we prepare past papers for the MDCAT, providing practice and valuable insights 
              to improve your confidence and performance.
            </p>
            <p>
              We believe in the power of education and are committed to making learning accessible and fun for everyone. Thank you for choosing YouConect as your education partner. 
              Together we can do great things!
            </p>
          </div>
          <div className="about-image">
            <img src={aboutUsImage} alt="About Us" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default AboutUs;
