import React from 'react';

const StudyGuide = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: 'auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
      color: '#333',
      background: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    title: {
      fontSize: '2.5em',
      color: '#2c3e50',
      textAlign: 'center',
      marginBottom: '20px',
    },
    intro: {
      fontSize: '1.2em',
      marginBottom: '20px',
      textAlign: 'justify',
    },
    sectionTitle: {
      fontSize: '1.8em',
      color: '#2980b9',
      marginBottom: '10px',
    },
    list: {
      listStyleType: 'disc',
      paddingLeft: '20px',
      marginBottom: '20px',
    },
    listItem: {
      marginBottom: '10px',
    },
    conclusion: {
      background: '#ecf0f1',
      padding: '15px',
      borderRadius: '8px',
    },
    conclusionText: {
      fontSize: '1.2em',
      textAlign: 'justify',
    },
    link: {
      color: '#2980b9',
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    linkHover: {
      textDecoration: 'underline',
    },
    linksContainer: {
      marginTop: '40px',
      textAlign: 'center',
    },
    linksTitle: {
      fontSize: '1.8em',
      color: '#2c3e50',
      marginBottom: '20px',
    },
    linkButton: {
      display: 'inline-block',
      padding: '10px 20px',
      margin: '10px',
      color: '#fff',
      backgroundColor: '#2980b9',
      borderRadius: '5px',
      textDecoration: 'none',
      transition: 'background-color 0.3s',
    },
    linkButtonHover: {
      backgroundColor: '#1f5a78',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Essential Chemistry Topics for MDCAT Success</h1>
      <p style={styles.intro}>
        Chemistry is a pivotal subject in the MDCAT exam, and mastering its key topics can significantly boost your score. Here’s a precise guide to the essential chemistry topics you need to focus on for MDCAT success.
      </p>

      <section>
        <h2 style={styles.sectionTitle}>1. Atomic Structure</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Fundamental Particles: Protons, neutrons, and electrons.</li>
          <li style={styles.listItem}>Atomic Models: Bohr’s model, Quantum mechanical model.</li>
          <li style={styles.listItem}>Electron Configuration: Principles (Aufbau, Pauli, Hund’s rule).</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>2. Chemical Bonding and Molecular Structure</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Ionic and Covalent Bonds: Formation, properties.</li>
          <li style={styles.listItem}>Lewis Structures: Drawing and predicting molecule shapes.</li>
          <li style={styles.listItem}>Hybridization: sp, sp2, sp3 hybridization and molecular geometry.</li>
          <li style={styles.listItem}>Molecular Orbital Theory: Bonding and antibonding orbitals.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>3. States of Matter</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Gases: Ideal gas law, real gases, van der Waals equation.</li>
          <li style={styles.listItem}>Liquids and Solids: Intermolecular forces, phase transitions.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>4. Thermochemistry</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>First Law of Thermodynamics: Internal energy, enthalpy.</li>
          <li style={styles.listItem}>Calorimetry: Measuring heat changes.</li>
          <li style={styles.listItem}>Hess’s Law: Application in calculating reaction enthalpies.</li>
          <li style={styles.listItem}>Spontaneity and Entropy: Gibbs free energy.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>5. Chemical Equilibrium</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Dynamic Equilibrium: Concept and characteristics.</li>
          <li style={styles.listItem}>Le Chatelier’s Principle: Predicting the effect of changes in conditions.</li>
          <li style={styles.listItem}>Equilibrium Constant (Kc and Kp): Calculations and applications.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>6. Acids, Bases, and Salts</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>pH and pOH: Calculations and interpretations.</li>
          <li style={styles.listItem}>Buffer Solutions: Composition and function.</li>
          <li style={styles.listItem}>Acid-Base Titrations: Indicators, titration curves.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>7. Electrochemistry</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Redox Reactions: Oxidation numbers, balancing equations.</li>
          <li style={styles.listItem}>Electrochemical Cells: Galvanic and electrolytic cells, cell potential.</li>
          <li style={styles.listItem}>Nernst Equation: Calculating cell potentials under non-standard conditions.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>8. Chemical Kinetics</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Reaction Rates: Factors affecting rates, rate laws.</li>
          <li style={styles.listItem}>Order of Reaction: Determination from experimental data.</li>
          <li style={styles.listItem}>Activation Energy: Arrhenius equation and its significance.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>9. Organic Chemistry</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Hydrocarbons: Alkanes, alkenes, alkynes, and their reactions.</li>
          <li style={styles.listItem}>Functional Groups: Identification and reactions of alcohols, ethers, aldehydes, ketones, carboxylic acids, and amines.</li>
          <li style={styles.listItem}>Nomenclature: IUPAC naming conventions.</li>
          <li style={styles.listItem}>Isomerism: Structural, geometric, and optical isomerism.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.sectionTitle}>10. Environmental Chemistry</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Pollution: Types, causes, and effects of air and water pollution.</li>
          <li style={styles.listItem}>Green Chemistry: Principles and applications in sustainable practices.</li>
        </ul>
      </section>

      <section style={styles.conclusion}>
        <h2 style={styles.sectionTitle}>Conclusion</h2>
        <p style={styles.conclusionText}>
          Thoroughly understanding these essential chemistry topics is crucial for MDCAT success. By focusing on these areas, practicing regularly, and utilizing resources like past papers and weekly tests available on our website, you can build a strong foundation in chemistry and improve your chances of scoring high in the MDCAT exam.
          Visit <a href="https://www.youconect.com/" target="_blank" rel="noopener noreferrer" style={styles.link} onMouseOver={(e) => e.currentTarget.style.textDecoration = styles.linkHover.textDecoration} onMouseOut={(e) => e.currentTarget.style.textDecoration = 'none'}>YouConect</a> for more resources and start your journey towards MDCAT success today!
        </p>
      </section>

      <div style={styles.linksContainer}>
        <h2 style={styles.linksTitle}>Explore More Resources</h2>
        <a 
          href="/questions/2" 
          style={styles.linkButton} 
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.linkButtonHover.backgroundColor} 
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.linkButton.backgroundColor}
        >
          Chemistry Challenging Questions
        </a>
        <a 
          href="/tests" 
          style={styles.linkButton} 
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.linkButtonHover.backgroundColor} 
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.linkButton.backgroundColor}
        >
          Mdcat Tests
        </a>
        <a 
          href="/bioguide" 
          style={styles.linkButton} 
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.linkButtonHover.backgroundColor} 
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.linkButton.backgroundColor}
        >
          Biology For MDCAT
        </a>
        <a 
          href="/phyguide" 
          style={styles.linkButton} 
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.linkButtonHover.backgroundColor} 
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.linkButton.backgroundColor}
        >
          Physics For MDCAT
        </a>
      </div>
    </div>
  );
};

export default StudyGuide;
