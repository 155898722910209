import React, { Suspense, lazy } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";

const Pdf = lazy(() => import("../components/test/pdf"));

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-x: auto;

  @media (max-width: 500px) {
    right: 100px;
  }
`;

const SectionHeading = styled.h2`
  width: 100%;
  text-align: left;
  padding: 10px 0;
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
  color: ${({ theme }) => theme.text};
`;

const ComingSoon = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px 0;
  font-size: 1.5em;
  color: blue;
  border: 2px dashed ${({ theme }) => theme.primary};
  border-radius: 10px;
  margin: 10px 0;
  background-color: lightyellow;
`;

const UpdatesContainer = styled.div`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
`;

const UpdatesTitle = styled.h2`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;

const UpdateItem = styled.p`
  margin: 5px 0;
  color: ${({ theme }) => theme.text};
`;

const VideoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
`;

const VideoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  loading: lazy; // Lazy loading
`;

const VideoTitle = styled.h3`
  padding: 10px;
  font-size: 1.2em;
  color: black;
`;

const VideoDescription = styled.p`
  padding: 0 10px 10px;
  color: black;
`;

const QuestionList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  width: 100%;
`;

const QuestionItem = styled.li`
  margin: 10px 0;
  border: 1px solid #00ff00;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.background};
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.hoverBackground};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
  }
`;

const QuestionLink = styled(Link)`
  display: block;
  padding: 15px;
  text-decoration: none;
  color: ${({ theme }) => theme.text};
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const Button = styled(Link)`
  display: inline-block;
  padding: 15px 30px;
  margin: 20px 0;
  background-color: rgb(0, 123, 255);
  color: #fff;
  border: none;
  border-radius: 25px;
  text-align: center;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: blue;
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const Home = ({ searchQuery = '' }) => {
  const navigate = useNavigate();

  return (
    <HelmetProvider>
      <Helmet>
        <title>YouConect - MDCAT 2024 Preparation</title>
        <meta name="description" content="Ace MDCAT 2024 with our daily tests, comprehensive videos, and detailed notes. Your ultimate prep starts here!" />
        <meta name="keywords" content="youconect mdcat, youconect, mdcat, MDCAT 2024, learning, past papers, online education, test preparation, medical entrance exams" />
        <link rel="canonical" href="https://youconect.com/" />
        <meta property="og:title" content="YouConect - MDCAT 2024 Preparation" />
        <meta property="og:description" content="Ace MDCAT 2024 with our daily tests, comprehensive videos, and detailed notes. Your ultimate prep starts here!" />
        <meta property="og:url" content="https://youconect.com/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="YouConect - MDCAT 2024 Preparation" />
        <meta name="twitter:description" content="Ace MDCAT 2024 with our daily tests, comprehensive videos, and detailed notes. Your ultimate prep starts here!" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "YouConect",
            "url": "https://youconect.com",
            "description": "Ace MDCAT 2024 with our daily tests, comprehensive videos, and detailed notes. Your ultimate prep starts here!"
          })}
        </script>
      </Helmet>
      <Container>
        <UpdatesContainer>
          <UpdatesTitle>Welcome to YouConect!</UpdatesTitle>
          <UpdateItem>Ace MDCAT 2024 with our daily tests, on-demand solutions.</UpdateItem>
          <UpdateItem>Comprehensive videos, and detailed notes.</UpdateItem>
          <UpdateItem>Your ultimate prep starts here!</UpdateItem>
          <UpdateItem>Plus, share your tests and videos via Dropbox and send the link to</UpdateItem>
          <UpdateItem>mudassir.muneer123@gmail.com, We'll review and upload them for everyone!</UpdateItem>
        </UpdatesContainer>
        <SectionHeading>QUESTIONS</SectionHeading>
        <QuestionList>
          <QuestionItem>
            <QuestionLink to="/questions/1">Physics Most Challenging Questions</QuestionLink>
          </QuestionItem>
          <QuestionItem>
            <QuestionLink to="/questions/2">Chemistry Most Challenging Questions</QuestionLink>
          </QuestionItem>
          <QuestionItem>
            <QuestionLink to="/questions/3">Biology Most Challenging Questions</QuestionLink>
          </QuestionItem>
          <QuestionItem>
            <QuestionLink to="/guide">How to Score 180+ Marks in MDCAT 2024</QuestionLink>
          </QuestionItem>
          <QuestionItem>
            <QuestionLink to="/engguide">Improving English Skills for MDCAT 2024: Tips and Tricks</QuestionLink>
          </QuestionItem>
          {/* Add other questions similarly */}
        </QuestionList>
        <SectionHeading>TEST</SectionHeading>
        <Suspense fallback={<div>Loading...</div>}>
          <Pdf />
        </Suspense>
        <SectionHeading>Tips For Mdcat 2024</SectionHeading>

        <Button to="/bioguide">Read the Comprehensive Biology Study Guide for MDCAT</Button>
        <Button to="/phyguide">Mastering Physics for MDCAT: Key Concepts and Practice Tips</Button>
        <Button to="/chemguide">Mastering Physics for MDCAT: Key Concepts and Practice Tips</Button>
        <Button to="/bioflashcard">Biology Flashcards</Button> 
        <Button to="/phyflashcard">Physics Flashcards</Button> 
        <Button to="/chemflashcard">Chemistry Flashcards</Button>
        <SectionHeading>IMPORTANT FOLMULAS</SectionHeading>
        {/* { <Button to="/phyimpformula">Go to Physics Important Formulas</Button> */}
        <SectionHeading>GRAND TESTS</SectionHeading>
        <ComingSoon>COMING SOON</ComingSoon>
      </Container>
    </HelmetProvider>
  );
};

export default Home;
