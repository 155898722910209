export const lightTheme = {
  bg: "#FAFAFA", // A soft, light background
  bgLighter: "#FFFFFF", // Pure white for lighter areas
  text: "#333333", // Dark grey for text to reduce contrast
  textSoft: "#777777", // Medium grey for softer text
  soft: "#E0E0E0", // Light grey for soft elements
  primary: "#007BFF", // Soft blue for primary actions
  secondary: "#6C757D", // Muted grey for secondary actions
  accent: "#FFD700", // Soft yellow for accents
};
export const darkTheme = {
  bg: "#181818",
  bgLighter: "#202020",
  text: "white",
  textSoft: "#aaaaaa",
  soft: "#373737",
};
