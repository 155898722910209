import React, { useState } from 'react';
import styled from 'styled-components';
import { FaLeaf } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

const FlashcardsContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
`;

const Flashcard = styled.article`
  perspective: 1000px;
  margin: 20px 0;
  cursor: pointer;
`;

const FlashcardInner = styled.div`
  position: relative;
  width: 300px;
  height: 200px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: ${({ theme }) => theme.cardBackground};
  color: ${({ theme }) => theme.text};
  ${(props) => props.flipped && 'transform: rotateY(180deg);'}
`;

const FlashcardFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
`;

const FlashcardFront = styled(FlashcardFace)`
  background: ${({ theme }) => theme.cardBackground};
`;

const FlashcardBack = styled(FlashcardFace)`
  background: ${({ theme }) => theme.primary};
  color: #fff;
  transform: rotateY(180deg);
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
`;

const Button = styled.button`
  background: gray;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;

  &:hover {
    background: ${({ theme }) => theme.primaryHover};
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const ProgressIndicator = styled.div`
  margin: 10px 0;
  font-size: 1.2em;
  color: ${({ theme }) => theme.text};
`;

const ToggleButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background: blue;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;

  &:hover {
    background: ${({ theme }) => theme.primaryHover};
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const Heading = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
  color: #ffffff;
  text-align: center;
  margin: 20px 0;
  padding: 15px 20px;
  background: linear-gradient(45deg, #1d976c, #93f9b9);
  background-size: 400% 400%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  .icon {
    margin-right: 10px;
    font-size: 1.2em;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 100%;
`;

const NavLink = styled(Link)`
  background: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 1.5em;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;

  &:hover {
    background: #45a049;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const KeyPointsSection = styled.div`
  margin-top: 30px;
  width: 80%;
  max-width: 800px;
  text-align: left;
  font-size: 1.2em;
  color: ${({ theme }) => theme.text};
`;

const ChapterNavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
`;

const chapters = [
  {
    title: 'Chapter 1: Introduction to Chemistry',
    keyPoints: [
      'Chemistry is the study of matter and its properties.',
      'Law of Conservation of Mass - Antoine Lavoisier, 1789.',
      'Law of Definite Proportions - Joseph Proust, 1797.',
      'Law of Multiple Proportions - John Dalton, 1803.',
      'Avogadro’s Hypothesis - Amedeo Avogadro, 1811.',
      'Periodic Law - Dmitri Mendeleev, 1869.',
      'Mole Concept - Defined by Avogadro’s number 6.022 × 10^23.',
      
    ],
  },
  {
    title: 'Chapter 2: Atomic Structure',
    keyPoints: [
      'Atoms are composed of protons, neutrons, and electrons.',
      'Discovery of the Electron - J.J. Thomson, 1897.',
      'Rutherford’s Gold Foil Experiment - Discovery of nucleus, 1911.',
      'Bohr’s Model of the Atom - Niels Bohr, 1913.',
      'Quantum Mechanical Model - Schrödinger, Heisenberg, Dirac, 1926.',
    ],
  },
  {
    title: 'Chapter 3: Gases',
    keyPoints: [
      'Boyle’s Law - Relationship between pressure and volume, 1662.',
      'Charles’s Law - Relationship between volume and temperature, 1787.',
      'Avogadro’s Law - Relationship between volume and number of moles, 1811.',
      'Ideal Gas Law - PV=nRT.',
      'Graham’s Law of Effusion - Thomas Graham, 1829.',
    ],
  },
  {
    title: 'Chapter 4: Liquids',
    keyPoints: [
      'Vapor Pressure - Pressure exerted by vapor in equilibrium with liquid.',
      'Boiling Point - Temperature at which vapor pressure equals atmospheric pressure.',
      'Surface Tension - Cohesive forces at the surface of a liquid.',
      'Viscosity - Resistance to flow.',
    ],
  },
  {
    title: 'Chapter 5: Solids',
    keyPoints: [
      'Crystalline Solids - Regular geometric patterns.',
      'Amorphous Solids - No long-range order.',
      'Types of Crystalline Solids - Ionic, Covalent, Metallic, Molecular.',
      'Unit Cell - Smallest repeating unit in a crystal lattice.',
    ],
  },
  {
    title: 'Chapter 6: Chemical Equilibrium',
    keyPoints: [
      'Le Chatelier’s Principle - Response of system to disturbances.',
      'Equilibrium Constant (Kc) - Ratio of product concentrations to reactant concentrations.',
      'Reaction Quotient (Q) - Determines direction of shift to reach equilibrium.',
    ],
  },
  {
    title: 'Chapter 7: Reaction Kinetics',
    keyPoints: [
      'Rate Law - Relationship between rate of reaction and concentration of reactants.',
      'Activation Energy - Minimum energy required for a reaction.',
      'Catalysts - Substances that increase reaction rate without being consumed.',
    ],
  },
  {
    title: 'Chapter 8: Thermo-chemistry and Energetics of Chemical Reactions',
    keyPoints: [
      'First Law of Thermodynamics - Law of Energy Conservation.',
      'Enthalpy (ΔH) - Heat content of a system.',
      'Entropy (ΔS) - Measure of disorder.',
      'Gibbs Free Energy (ΔG) - Determines spontaneity of reactions.',
    ],
  },
  {
    title: 'Chapter 9: Electrochemistry',
    keyPoints: [
      'Galvanic Cells - Convert chemical energy to electrical energy.',
      'Electrolytic Cells - Use electrical energy to drive non-spontaneous reactions.',
      'Standard Electrode Potentials (E°) - Measure of the tendency of a chemical species to be reduced.',
      'Nernst Equation - Relationship between electrode potential and concentration.',
    ],
  },
  {
    title: 'Chapter 10: Chemical Bonding',
    keyPoints: [
      'Ionic Bonds - Electrostatic attraction between oppositely charged ions.',
      'Covalent Bonds - Sharing of electron pairs between atoms.',
      'Metallic Bonds - Delocalized electrons in a metal lattice.',
      'Hybridization - Mixing of atomic orbitals to form new hybrid orbitals.',
      'Molecular Orbital Theory - Description of electrons in molecules in terms of molecular orbitals.',
    ],
  },
  {
    title: 'Chapter 11: S and P Block Elements',
    keyPoints: [
      'Group 1 Elements (Alkali Metals) - Highly reactive, soft metals.',
      'Group 2 Elements (Alkaline Earth Metals) - Less reactive than alkali metals.',
      'Group 13-18 Elements - Including Boron, Carbon, Nitrogen, Oxygen, Halogens, and Noble Gases.',
      'Trends in Periodic Table - Atomic size, ionization energy, electron affinity, electronegativity.',
    ],
  },
  {
    title: 'Chapter 12: Transition Elements',
    keyPoints: [
      'D-block Elements - Exhibit variable oxidation states, colored compounds.',
      'Complex Formation - Transition metals form coordination compounds.',
      'Magnetic Properties - Paramagnetism and diamagnetism.',
    ],
  },
  {
    title: 'Chapter 13: Fundamental Principles of Organic Chemistry',
    keyPoints: [
      'Hydrocarbons - Alkanes, alkenes, alkynes.',
      'Functional Groups - Alcohols, ethers, aldehydes, ketones, carboxylic acids, amines, etc.',
      'Isomerism - Structural, geometrical, and optical isomers.',
    ],
  },
  {
    title: 'Chapter 14: Chemistry of Hydrocarbons',
    keyPoints: [
      'Alkanes - Saturated hydrocarbons, single bonds.',
      'Alkenes - Unsaturated hydrocarbons, double bonds.',
      'Alkynes - Unsaturated hydrocarbons, triple bonds.',
    ],
  },
  {
    title: 'Chapter 15: Alkyl Halides',
    keyPoints: [
      'Nomenclature - Naming based on IUPAC rules.',
      'Reactivity - Nucleophilic substitution and elimination reactions.',
      'SN1 and SN2 Mechanisms - Substitution reaction mechanisms.',
    ],
  },
  {
    title: 'Chapter 16: Alcohols and Phenols',
    keyPoints: [
      'Classification - Primary, secondary, tertiary alcohols.',
      'Reactivity - Oxidation reactions, dehydration.',
      'Phenols - Acidic nature, electrophilic substitution.',
    ],
  },
  {
    title: 'Chapter 17: Aldehydes and Ketones',
    keyPoints: [
      'Nucleophilic Addition Reactions - Formation of hemiacetals, acetals.',
      'Oxidation and Reduction - Tollen’s test, Fehling’s test.',
      'Aldol Condensation - Reaction between aldehydes/ketones.',
    ],
  },
  {
    title: 'Chapter 18: Carboxylic Acids',
    keyPoints: [
      'Acidity - Strong acids compared to alcohols and phenols.',
      'Derivatives - Esters, amides, anhydrides.',
      'Decarboxylation - Loss of CO2 from carboxylic acids.',
    ],
  },
  {
    title: 'Chapter 19: Macromolecules',
    keyPoints: [
      'Polymers - Natural and synthetic polymers.',
      'Addition Polymers - Polyethylene, polystyrene.',
      'Condensation Polymers - Nylon, polyester.',
    ],
  },
];


const Flashcards = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [currentChapter, setCurrentChapter] = useState(0);

  const flashcards = [
    { question: "The horizontal rows of the periodic table are called?", answer: "Periods." },
    { question: "Which metallic element is liquid at room temperature?", answer: "Mercury." },
    { question: "The property of a substance to absorb moisture from the air on exposure is called?", answer: "Deliquescence." },
    { question: "Hydrogen peroxide (H2O2) is?", answer: "Poisonous to tissues." },
    { question: "The fraction of petroleum having boiling range 40-200C?", answer: "Gasoline." },
    { question: "Which of the following metals occurs in free state?", answer: "Lead." },
    { question: "Which element does not occur in nature but can be produced artificially?", answer: "Plutonium." },
    { question: "Mercury is also known as?", answer: "Quick Silver." },
    { question: "Which of the following is a substance?", answer: "Graphite." },
    { question: "The chemical name of laughing gas?", answer: "Nitrous oxide." },
    { question: "What is the freezing point of water?", answer: "0 degrees Celsius." },
    { question: "The unpleasant odor of the water is due to the presence of?", answer: "Phenols." },
    { question: "The calomel electrode is only used as?", answer: "Reducing electrode." },
    { question: "What does not get lost during corrosion?", answer: "Color." },
    { question: "The radioactive isotope of hydrogen is called?", answer: "Tritium." },
    { question: "Sodium carbonate is produced by?", answer: "Ammonia Solvay process." },
    { question: "Which is a mixture of potassium nitrate, powdered charcoal, and sulfur called?", answer: "Gunpowder." },
    { question: "Which of the following terms is not used for ionic compounds?", answer: "Molecular formula." },
    { question: "The mass of a decimole of electrons (NA) is?", answer: "0.054." },
    { question: "Combustion analysis is performed to determine?", answer: "Empirical formula." },
    { question: "The nucleus of an atom of every element will always contain?", answer: "Protons." },
    { question: "The hardest of the following solids is?", answer: "Diamond." },
    { question: "Which of the following elements does not show allotropy?", answer: "Nitrogen." },
    { question: "Quantum number values for 3p orbitals are?", answer: "n=3, l=1." },
    // Add more flashcards here
  ];

  const handleNextFlashcard = () => {
    if (currentIndex < flashcards.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setShowAnswer(false);
    }
  };

  const handlePrevFlashcard = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setShowAnswer(false);
    }
  };

  const handleNextChapter = () => {
    if (currentChapter < chapters.length - 1) {
      setCurrentChapter(currentChapter + 1);
      setCurrentIndex(0); // Reset flashcard index for the new chapter
      setShowAnswer(false);
    }
  };

  const handlePrevChapter = () => {
    if (currentChapter > 0) {
      setCurrentChapter(currentChapter - 1);
      setCurrentIndex(0); // Reset flashcard index for the previous chapter
      setShowAnswer(false);
    }
  };

  const toggleAnswer = () => {
    setShowAnswer((prev) => !prev);
  };

  return (
    <>
      <Helmet>
        <title>Chemistry Flashcards | MDCAT Preparation</title>
        <meta name="description" content="Chemistry flashcards for MDCAT preparation. Learn key concepts with our flashcards and ace the MDCAT." />
        <meta name="keywords" content="MDCAT, Chemistry, Flashcards, MDCAT Preparation, Learning, Chemistry Questions and Answers" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Chemistry Flashcards | MDCAT Preparation",
            "description": "Chemistry flashcards for MDCAT preparation. Learn key concepts and improve your knowledge with our interactive flashcards.",
            "mainEntity": {
              "@type": "CreativeWork",
              "name": "Chemistry Flashcards",
              "about": {
                "@type": "Thing",
                "name": "MDCAT Chemistry"
              },
              "educationalLevel": "High School",
              "isAccessibleForFree": "True",
              "learningResourceType": "Flashcards",
              "audience": {
                "@type": "Audience",
                "audienceType": "Students"
              },
              "keywords": "MDCAT, Chemistry, Flashcards, MDCAT Preparation, Learning, Chemistry Questions and Answers"
            }
          }
          `}
        </script>
      </Helmet>
      <FlashcardsContainer>
        <Heading>
          <FaLeaf className="icon" />
          Chemistry Flashcards
        </Heading>
        <Flashcard>
          <FlashcardInner flipped={showAnswer}>
            <FlashcardFront>
              {flashcards[currentIndex].question}
            </FlashcardFront>
            <FlashcardBack>
              {flashcards[currentIndex].answer}
            </FlashcardBack>
          </FlashcardInner>
        </Flashcard>
        <ToggleButton onClick={toggleAnswer}>
          {showAnswer ? "Hide Answer" : "Show Answer"}
        </ToggleButton>
        <ProgressIndicator>
          {currentIndex + 1} / {flashcards.length}
        </ProgressIndicator>
        <NavigationButtons>
          <Button onClick={handlePrevFlashcard} disabled={currentIndex === 0}>
            Previous Flashcard
          </Button>
          <Button onClick={handleNextFlashcard} disabled={currentIndex === flashcards.length - 1}>
            Next Flashcard
          </Button>
        </NavigationButtons>
        <KeyPointsSection>
          <h3>{chapters[currentChapter].title}</h3>
          <ul>
            {chapters[currentChapter].keyPoints.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        </KeyPointsSection>
        <ChapterNavigationButtons>
          <Button onClick={handlePrevChapter} disabled={currentChapter === 0}>
            Previous Chapter
          </Button>
          <Button onClick={handleNextChapter} disabled={currentChapter === chapters.length - 1}>
            Next Chapter
          </Button>
        </ChapterNavigationButtons>
        <LinksContainer>
          <NavLink to="/phyflashcard">Physics Flashcard</NavLink>
          <NavLink to="/bioflashcard">Biology Flashcard</NavLink>
        </LinksContainer>
      </FlashcardsContainer>
    </>
  );
};

export default Flashcards;
